/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

html:not(.nav-open) .personalize-index-index {
    .page-header {
        .logo {
            overflow: hidden;
        }
    }
}

@keyframes lit {
    0% {background-position:10% 0% }
    50% {background-position:91% 100% }
    100% {background-position:10% 0% }
}

.personalize-container {
    position: relative;
    color: $color-brownie1;
    z-index: 30;
    width: 100%;

    #maincontent {
        display: none;
    }

    &.editor-open {
        z-index: 60;
    }

    .personalize-heading {
        margin-top: 0;
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        color: $color-black;
        margin-bottom: 20px;
    }

    .config-container {
        margin-bottom: 20px;
    }

    .sub-header {
        position: relative;
        text-align: center;
        padding: 0 15px;
        margin-bottom: 30px;

        .sub-heading {
            margin: 0;
            color: $color-black;
            font-size: 12px;
            font-weight: 500;
            line-height: 22px;
        }

        button {
            font-weight: 300;
            line-height: 22px;
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;

            &.back {
                position: absolute;
                left: 0;
                background: none;
                border: unset;
                color: $color-brownie1;
                padding: 0;
                background-image: url('../media/Back@2x.png');
                background-repeat: no-repeat;
                background-position: 0 center;
                background-size: 16px;
                padding-left: 30px;
                font-size: 0;
                text-transform: lowercase;

                @include min-screen($screen__m) {
                    font-size: 12px;
                }
            }
        }
    }

    .side-container,
    .option-container {
        margin-bottom: 30px;

        @include screen($screen__l, $screen__xl) {
            width: $screen__xxs;
        }

        @include min-screen($screen__xl) {
            width: $screen__xs;
        }
    }

    .configurator-input {
        padding: 25px 15px;

        .config-action {
            position: fixed;
            bottom: 80px;
            left: 15px;
            right: 15px;
            z-index: 1;

            @include screen($screen__s, $screen__l) {
                left: 50%;
                width: 100%;
                right: auto;
                max-width: 400px;
                transform: translateX(-50%);
            }

            button {
                padding: 15px;
                color: $color-white;
                font-size: 14px;
                line-height: 22px;
                font-weight: 400;
                display: block;
                width: 100%;
                margin: 1px;
                cursor: pointer;
                background-color: $color-brownie1;
                border: 1px solid $color-white;
                outline: 1px solid $color-brownie1;

                &:after {
                    content: "";
                    background-image: url('../media/Next@2x.png');
                    background-repeat: no-repeat;
                    background-position: center;
                    display: inline-block;
                    width: 20px;
                    height: 16px;
                    background-size: 16px;
                    vertical-align: -3px;
                }

                &:hover {
                    background-color: mix(white, $color-brownie1, 10%);
                }
            }

            @include min-screen($screen__l) {
                width: 320px;
                right: 50px;
                left: auto;
            }

            @include min-screen($screen__xl) {
                width: 480px;
                right: 50px;
                left: auto;
            }
        }

        @include min-screen($screen__m) {
            padding: 50px;
        }

        @include min-screen($screen__l) {
            margin-top: 45px;
        }
    }

    @include min-screen($screen__l) {
        position: fixed;
        top: 80px;
        bottom: 0;

        .personalize-configurator {
            @include full-spread();
            margin-bottom: 0;
            display: flex;

            .configurator-preview {
                margin-top: 45px;
                flex: 1;
                position: relative;
                overflow: hidden;
            }

            .configurator-input {
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                overflow: auto;
            }
        }
    }

    .configure-actions {
        margin: 0 -5px 20px;
    }

    .full-screen-container {
        position: fixed;
        z-index: 22;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding-bottom: 50px;
        overflow: auto;
        background-color: #030303;

        &.collager-container {
            z-index: 22;
            background-color: rgb(37, 37, 37);

            @include max-screen($screen__l) {
                background-color: #161616;
            }
        }

        .sub-header {
            z-index: 10;
            margin: 0;
            background-color: #252525;
            color: $color-white;
            border-bottom: 1px solid $color-black;
            padding: 15px 15px;
            height: 50px;
            box-sizing: border-box;

            &:after {
                content: "";
                display: block;
                background-image: url('../media/logo-flame.png');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 36px;
                height: 50px;
                margin-top: -15px;

                @include min-screen($screen__l) {
                    margin-left: 282px;
                }
            }

            .back {
                color: $color-white;
                background-image: url('../media/arrow-left-white.svg');
                left: 15px;
                text-transform: lowercase;
                letter-spacing: 1.5px;
            }
        }

        .config-container {
            position: fixed;
            top: 40px;
            right: 0;
            bottom: 50px;
            left: 0;
            margin: 0;

            @include min-screen($screen__l) {
                top: 50px;
            }
        }

        .configure-actions {
            margin-bottom: 0;
        }
    }


    .edit-image-options {
        .options {
            text-align: center;
            @include min-screen($screen__l) {
                margin-top: 50%;
            }
        }

        .discard {
            padding: 0;
            text-align: center;
            margin-top: 0;

            button.btn.icon.cancel {
                padding: 10px 25px;
            }
        }
    }
}

.product-attributes {
    @include min-screen($screen__l) {
        padding-bottom: 100px;
    }
}

.personalize-configurator.edit {
    .personalize-product .option-container {
        pointer-events: none;

        .personalize-heading,
        .label,
        .option-container .label,
        .label .option-title,
        input[type="radio"]:checked+.label,
        input[type="radio"]:checked+.label .option-title {
            color: $color-gray-light3;
            border-color: $color-gray-light3;
        }

        input[type="radio"]:checked+.label {
            background-image: linear-gradient(135deg, rgba($color-gray-darken4, .2) 7.14%, $color-white 7.14%, $color-white 50%, rgba($color-gray-darken4, .2)  50%, rgba($color-gray-darken4, .2)  57.14%, $color-white 57.14%, $color-white 100%);
        }
    }

    .personalize-footer {
        .qty input {
            pointer-events: none;
            border: none;
        }
    }
}

#personalize-loader {
    .welcome {
        margin-top: 30px;
        color: $color-white;
        font-size: 14px;
        width: 100%;
        text-align: center;
    }
}

.personalize-index-index .page-header {
    margin-bottom: 0;
    z-index: 50;
}

.personalize-loading {
    .personalize-footer {
        background-color: $color-black;
        .cart {
            transition: 0.2s;
            opacity: 0;
        }
    }

    .personalize-footer.action .action,
    .personalize-container .configurator-input .config-action {
        transition: 0.2s;
        opacity: 0;
    }
}
