/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.table.grouped {
    @include lib-table-bordered(
        $_table_type: horizontal
    );

    > thead > tr > th {
        border-bottom: 0;
    }

    .product-item-name {
        font-weight: $font-weight__regular;
    }

    .price-box {
        .price {
            font-size: $font-size__base;
        }
    }

    .control {
        .qty {
            @extend .abs-input-qty;
        }
    }

    .stock.unavailable {
        font-weight: normal;
        margin: 0;
        text-transform: none;
        white-space: nowrap;
    }

    .row-tier-price {
        td {
            border-top: 0;
        }

        .prices-tier {
            margin: 0 -10px 10px;
        }
    }
}
