/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin              : $indent__base !default;
$checkout-wrapper__columns             : 8 !default;

$checkout-step-title__border           : $border-width__base solid $color-gray-light8 !default;
$checkout-step-title__font-size        : 18px !default;
$checkout-step-title__font-weight      : $font-weight__bold !default;
$checkout-step-title__padding          : 15px !default;

$checkout-step-title-mobile__font-size : 18px !default;

.checkout-container {
    @extend .abs-add-clearfix;
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);
}

.opc-wrapper {
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);
    font-size: 14px;

    .opc {
        @extend .abs-reset-list;
    }

    .step-title {
        @extend .abs-checkout-title;
    }

    .step-content {
        margin: 0 0 $indent__xl;

        &#checkout-step-store-selector {
            .form-continue {
                margin-top: $indent__xl;

                .primary {
                    float: right;
                    button {
                        padding: 10px 30px;
                    }
                }

                .secondary {
                    float: left;
                    margin-top: 0;

                    button {
                        padding: 10px 30px;
                    }
                }
            }
        }
    }
}

.checkout-onepage-success {
    @extend .abs-add-clearfix;

    .print {
        display: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .step-title {
            @include lib-css(font-size, $checkout-step-title-mobile__font-size);
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-wrapper {
        @extend .abs-add-box-sizing-desktop-m;
        @include lib-layout-column(2, 1, $checkout-wrapper__columns);
        padding-right: 40px;
    }

    .checkout-onepage-success {
        .print {
            display: block;
            margin: 22px 0;
        }
    }
}

#delivery-method-selector {
    font-size: 0;

    button {
        margin-right: 10px;
        color: $color-gray-light8;
        border-color: $color-gray-light7;

        &.selected {
            color: $color-black;
            border-color: $color-brownie1;
        }
    }
}

#store-pickup.selected-store-pickup ~ #shipping, #store-pickup.selected-store-pickup ~ #opc-shipping_method {
    visibility: hidden;
    height: 0;
    overflow: hidden;
}

[name*="street.0"] {
    .label {
        display: none;
    }
}

#store-pickup #opc-store-selector-popup {
    display: none;
}

#checkout-step-store-selector {
    .no-selected-location {
        max-width: 360px;
        font-weight: 300;
    }
}

//.selected-location-details
.location-details {
    .location-name {
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 15px;
        color: $color-brownie1;
    }

    .location-address {
        text-transform: uppercase;
        margin-bottom: 15px;
        font-size: 12px;
    }

    .location-description {

    }
}

.opc#checkoutSteps {
    display: grid;
}

#opc-shipping_method {
    order: 4;
}

.opc-rush-delivery-step.opc-block-rush-delivery {
    order: 3;

    .price {
        float: none;
        font-size: 14px;
        font-weight: normal;
    }

    .label {
        padding-left: 30px;
    }

    .description {
        margin-top: 15px;

        &.warning .message.error {
            display: inline-block;
        }
    }
}

.checkout-index-index {
    .modal-custom .action-close:before {
        color: $color-white;
    }
}

.checkout-success {
    .actions-toolbar {
        margin-top: 30px;

        .action.primary {
            margin: 0 0 15px;
        }
    }
}

hr {
    display: none;
}
