/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.fotorama__zoom-in,
.fotorama__zoom-out {
    display: none;
    z-index: $z-index-10;
    &:focus {
        @extend .fotorama-focus;
        box-shadow: none;
        &:after {
            border-radius: inherit;
            bottom: $fotorama-inner-box-shadow;
            box-shadow: $focus__box-shadow;
            content: '';
            left: $fotorama-inner-box-shadow;
            position: absolute;
            right: $fotorama-inner-box-shadow;
            top: $fotorama-inner-box-shadow;
            z-index: $z-index-10;
        }
    }

    .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) & {
        @include fotorama-button-background();
    }

    .fotorama__fullscreen & {
        @extend .fotorama-sprite;
        position: absolute;
        display: block;
        width: $size-fotorama-block;
        height: $size-fotorama-block;
        overflow: hidden;
        cursor: pointer;
        &--disabled {
            display: none;
        }
    }
}

.fotorama__fullscreen {
    .fotorama__zoom-out {
        top: 80px;
        background-position: 0 (-$size-fotorama-block) !important;
    }

    .fotorama__zoom-in {
        background-position: 0 0 !important;
        top: 0;
    }
}
