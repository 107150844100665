/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.not-found-block {
    text-align: center;
    padding-top: 50px;

    .not-found-title {
        @include lib-heading(h1);
    }

    .not-found-text {
        font-size: 14px;
    }
}
