/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.candle-in-slider {
    position: relative;
    padding-bottom: 50%;
    transition: 0.2s;
    z-index: 1;


    &:after {
        @include full-spread();
        content: "";
        z-index: 1;
        background-color: rgba($color-brownie2, 0.5);
        opacity: 0;
        transition: 0.5s;
        pointer-events: none;
    }

    &.loading {
        &:after {
            opacity: 1;
        }

        .slide .slide-content {
            .title {
                opacity: 0;
                transition-delay: 0s;
            }

            .description {
                opacity: 0;
                transition-delay: 0.05s;
            }

            .link {
                opacity: 0;
                transition-delay: 0.1s;
            }
        }
    }

    .slide {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: none;
        background-color: rgba($color-brownie2, 0.5);

        &.selected {
            display: block;
        }

        .slide-bg {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background-size: cover;
            overflow: hidden;
            opacity: 0;

            &.loaded {
                transition: 0.2s;
                opacity: 1;
            }

            &:before {
                content: "";
                display: block;
                position: absolute;
                background-image: radial-gradient(circle 300px at 66.6% 62.3%, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 111%);
                opacity: 0.5;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
            }
        }

        .slide-content {
            z-index: 1;
            max-width: $screen__xxl;
            position: relative;
            margin: 0 auto;
            height: 100vh;

            .main {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                box-sizing: border-box;

                @include min-screen($screen__l) {
                    max-width: 40%;
                    margin-left: 8%;
                }

                @include max-screen($screen__l) {
                    width: 100%;
                    padding-left: 30px;
                    padding-right: 30px;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .title {
                color: $color-white;
                font-family: $font-family__headings;
                font-size: 56px;
                font-weight: 400;
                line-height: 62px;
                margin-bottom: 35px;
                opacity: 1;
                transition: 0.1s;
                transition-delay: 0s;
            }

            .description {
                color: $color-white;
                font-size: 16px;
                line-height: 26px;
                font-weight: 300;
                margin-bottom: 60px;
                opacity: 1;
                transition: 0.1s;
                transition-delay: 0.05s;
                @include min-screen($screen__l) {
                    max-height: 78px;
                }
            }

            .link {
                position: relative;
                opacity: 1;
                transition: 0.1s;
                transition-delay: 0.1s;

                a {
                    line-height: 16px;
                    padding: 18px 60px;
                    border: 1px solid $color-brownie1;
                    color: $color-white;
                    background-color: #0000000d;
                    position: relative;
                    text-transform: uppercase;
                    font-weight: 300;
                    letter-spacing: 1px;
                    font-size: 11px;
                    display: inline-block;
                    transition: 0.2s;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        width: 60px;
                        height: 1px;
                        background-color: $color-white;
                        right: -30px;
                        transition: 0.2s;
                    }

                    &:before {
                        content: "";
                        width: 0;
                        height: 0;
                        position: absolute;
                        right: -39px;
                        border-top: 6px solid transparent;
                        border-bottom: 6px solid transparent;
                        border-left: 4px solid $color-white;
                        margin-top: 3px;
                        transition: 0.2s;
                    }

                    &:hover {
                        text-decoration: none;
                        background-color: transparentize($color-brownie1, 0.5);
                        border-color: lighten($color-brownie1, 10%);
                    }
                }
            }
        }


        @include max-screen($screen__l) {
            text-align: center;

            .slide-content .link a {
                &:before,
                &:after {
                    display: none;
                }
            }
        }
    }

    .slider-nav {
        position: absolute;
        z-index: 1;
        font-size: 0;
        left: 30px;
        bottom: 40px;

        .slider-nav-list {
            margin-bottom: 0;
            padding: 0;
        }

        .slider-nav-list-item {
            list-style: none;
            display: inline-block;
            margin: 0 5px;

            &:only-child {
                display: none;
            }

            .nav-link {
                width: 7px;
                height: 7px;
                position: relative;
                border-radius: 50%;
                border: 1px solid $color-white;
                display: inline-block;
                background-color: transparent;
                cursor: pointer;
                transition: 0.3s;

                &:hover {
                    border-color: rgba($color-white, 0.5);
                }

                &:before,
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    height: 0;
                    width: 1px;
                    background-color: $color-white;
                    left: 3px;
                    transition: 0.3s;
                }

                &:before {
                    top: -13px;
                }

                &:after {
                    bottom: -13px;
                }
            }

            &.selected .nav-link {
                background-color: $color-white;

                &:before,
                &:after {
                    height: 7px;
                }
            }
        }


        @include max-screen($screen__m) {
            right: 30px;
            text-align: center;

            .slider-nav-list-item {
                margin: 0 15px;
            }
        }
    }

    &.include-footer {
        .slider-nav .slider-nav-list-item:last-child {
            vertical-align: -1px;

            .slider-nav-list-item-wrapper {
                position: relative;

                &:before,
                &:after {
                    display: block;
                    content: "";
                    position: absolute;
                    background-color: $color-white;
                    z-index: 1;
                    pointer-events: none;
                }

                &:before {
                    top: 5px;
                    left: 3px;
                    width: 5px;
                    height: 1px;
                }

                &:after {
                    top: 3px;
                    left: 5px;
                    width: 1px;
                    height: 5px;
                }

                .nav-link {
                    width: 9px;
                    height: 9px;
                    border-color: $color-brownie1;
                    background-color: $color-brownie1;

                    &:after,
                    &:before {
                        left: 4px;
                    }
                }
            }
        }

        .footer-container {
            padding-top: 140px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;
            width: auto;
            overflow: auto;

            @include max-screen($screen__m) {
                margin-top: 65px;
                padding-top: 35px;
                margin-bottom: 90px;
                padding-bottom: 0;

                .columns:first-child {
                    border-top: none;
                    padding-top: 0;
                }
            }
        }

        .slide:last-child {
            background-color: $color-black;
        }
    }

    &.home {
        position: fixed;
        padding-bottom: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        .slide .slide-bg {
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;

            .slide-video {
                width: 100%;
                height: 100%;
            }
        }
    }
}
