/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//
//  Default appearance styles
//  _____________________________________________

div[data-content-type='text'] {
    word-wrap: break-word;
}
