/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.collager-container {

    .collager-editor-container {
        background-color: rgb(3, 3, 3);
        font-size: 0;
        text-align: center;
        margin: 0;
        position: absolute;
        left: 286px;
        right: 0;
        top: 0;
        bottom: 0;
        width: auto;

        .collager-editor {
            position: absolute;
            margin: 28px;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            canvas {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    .selected-menu-item {
        display: block;
        width: 22px;
        height: 22px;
        padding: 12px;
        background-size: 50%;
        margin-left: 4px;
        position: absolute;

        svg {
            width: 22px;
        }
    }

    .collage-options {
        overflow: auto;
        text-align: left;
        left: 57px;
        width: 230px;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        background-color: rgb(22, 22, 22);

        .section-title {
            font-family: Inter, sans-serif;
            padding: 0 4px;
            background: rgba(23, 23, 23, 0.9);
            color: rgba(255, 255, 255, 0.6);
            border-top: 1px solid rgb(51, 51, 51);
            text-transform: uppercase;
            text-align: center;
            letter-spacing: 1.5px;
            line-height: 47px;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 0;
            margin-top: 35px;
        }

        .layouts {
            ol {
                padding: 0;
                margin: 0 -7.5px;
                font-size: 0;

                li {
                    display: inline-block;
                    font-size: 0;
                    vertical-align: top;
                    width: 50%;

                    .thumbnail {
                        position: relative;
                        display: block;
                        border: 2px solid #737373;
                        margin: 7.5px;
                        width: 75px;
                        height: 75px;
                        background-color: #252525;

                        &.reorder {
                            background-image: url('../media/reload.svg');
                            background-repeat: no-repeat;
                            background-position: center;
                        }

                        .thumbnail-render {
                            position: absolute;
                            top: 2px;
                            right: 2px;
                            bottom: 2px;
                            left: 2px;

                            .piece {
                                position: absolute;
                                background-color: #737373;
                                border: 2px solid #161616;
                                box-sizing: border-box;
                            }
                        }
                    }

                    &.selected {
                        .thumbnail {
                            border-color: $color-brownie1;
                            position: relative;

                            img {
                                opacity: .5;
                            }

                            &:after {
                                content:"";
                                display: block;
                                position: absolute;
                                top: 6px;
                                right: 6px;
                                width: 15px;
                                height: 15px;
                                background-image: url('../media/thumb-check.svg');
                                background-repeat: no-repeat;
                                background-position: center;
                            }
                        }
                    }
                }
            }
        }

        .option {
            padding: 23px 23px 0;

            &.edit {
                padding-top: 0;
            }

            label {
                margin: 0 auto 10px;
                font-size: 13px;
                line-height: 20px;
                font-weight: 400;
                font-smooth: antialiased;
                display: block;
                color: rgba(255, 255, 255, 0.6)
            }

            button {
                font-weight: 400;
                text-align: center;
                position: relative;
                padding: 4px 8px;
                min-height: 32px;
                border-radius: 4px;
                font-size: 12px;
                letter-spacing: 1.2px;
                text-transform: uppercase;
                cursor: pointer;
                border: none;
                width: 100%;
                height: 100%;
                background: rgb(36, 36, 36);
                color: rgba(255, 255, 255, 0.9);
                max-width: 250px;
                margin: 0 auto;
                display: block;
            }

            .slider-container {
                max-width: $screen__m;
                margin: -5px auto 0;
                font-size: 0;

                .slider {
                    appearance: none;
                    width: 70%;
                    display: inline-block;
                    height: 2px;
                    outline: none;
                    background: linear-gradient(to right, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%);
                    vertical-align: 4px;
                }

                .slider::-webkit-slider-thumb {
                    appearance: none;
                    width: 20px;
                    height: 20px;
                    border-radius: 10px;
                    margin: 1px;
                    border: 2px solid $color-brownie1;
                    background: #161616;
                    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 14px inset;
                    cursor: pointer;
                }

                .slider::-moz-range-thumb {
                    appearance: none;
                    width: 6px;
                    height: 6px;
                    border-radius: 10px;
                    margin: 1px;
                    border: 2px solid $color-brownie1;
                    background: #161616;
                    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 14px inset;
                    cursor: pointer;
                }

                .box {
                    width: 25%;
                    padding: 5px;
                    text-align: center;
                    display: inline-block;
                    margin-left: 5%;
                    height: 30px;
                    background-color: #737373;
                    border: 1px solid #161616;
                }
            }
        }
    }

    @include max-screen($screen__l) {
        &.full-screen-container {
            .config-container {
                position: relative;
                top: 0;
                bottom: auto;
                overflow: auto;
                height: auto;
                display: grid;
                flex-direction: unset;
                grid-template-columns: 0fr 1fr;
            }
        }

        .collager-editor-container {
            position: relative;
            flex-direction: unset;
            height: auto;
            display: block;
            grid-column: 1 / 3;
            grid-row: 1;
            left: 0;

            .collager-editor {
                position: relative;
                margin: 15px;
                left: auto;
                top: auto;
                right: auto;
                bottom: auto;

                canvas {
                    position: relative;
                    top: auto;
                    left: auto;
                    transform: none;
                    max-width: none;
                    max-height: none;
                    width: 100%;
                }
            }
        }

        .collage-options {
            display: block;
            flex: none;
            margin-bottom: 0;
            padding-bottom: 50px;
            grid-column: 2;
            grid-row: 2;
            width: auto;
            flex-direction: unset;
            position: relative;
            left: auto;

            .layouts ol li {
                width: auto;
            }
        }

        .selected-menu-item {
            display: block;
            flex: none;
            margin-bottom: 50px;
            padding-bottom: 50px;
            flex-direction: unset;
            height: auto;
            grid-column: 1;
            grid-row: 2;
            position: relative;
            left: auto;
        }

        .selected-menu-item,
        .collage-options {
            display: block;
            flex: none;
            padding-bottom: 50px;
        }
    }
}

.collager-edit-image-option {
    position: absolute;
    z-index: 60;
    padding: 25px;

    button {
        background-color: $color-white;
        border: 1px solid $color-brownie1;
        color: $color-brownie1;

        &:hover {
            color: $color-white;
            background-color: $color-brownie1;
            border: 1px solid $color-white;
        }
    }
}
