/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.breadcrumbs {
    @include lib-breadcrumbs();
    padding-left: 0;
    font-weight: 400;
    line-height: 22px;

    @include max-screen($screen__m) {
        margin: 30px 0;
    }

    &:before {
        content: "";
        display: inline-block;
        width: 60px;
        height: 1px;
        background-color: $color-black;
        margin: 9px 35px 0px 0;
        vertical-align: text-top;
    }

    .items {
        display: inline-block;
        color: $color-black;
    }

    .item:not(:last-child):after {
        vertical-align: -5px;
    }

    a {
        color: $color-brownie1;

        &:link,
        &:active,
        &:visited {
            color: $color-brownie1;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}
