/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.messages {
    .message:last-child {
        margin-bottom: 50px;
    }
}

.message.info {
    @include lib-message-icon-inner(info);
}

.message.error {
    @include lib-message-icon-inner(error);

    & > *:first-child:before {
        background-image: url('../media/warning-red.svg');
    }
}

.message.warning {
    @include lib-message-icon-inner(warning);
}

.message.notice {
    @include lib-message-icon-inner(notice);
}

.message.success {
    @include lib-message-icon-inner(success);

    & > *:first-child:before {
        background-image: url('../media/checkmark.svg');
    }
}

.message {
    border-radius: 8px;
    & > *:first-child:before {
        content: "";
        height: 18px;
        background-image: url('../media/warning.svg');
    }
}
