/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.fotorama__caption__wrap {
    @extend .fotorama-inline-block;
    padding: 5px 10px;
    box-sizing: border-box;
    background-color: $color-white;
}
