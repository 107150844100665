/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.fotorama__video {
    position: absolute;
    top: 32px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;

    iframe {
        @extend .fotorama-stretch;
    }
}
