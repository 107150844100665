/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.load.indicator {
    @include lib-loader();
    position: absolute;

    > span {
        display: none;
    }
}

.loading-mask {
    @include lib-loading-mask();
    background: rgba(0, 0, 0, 0.75);

    .welcome {
        margin: auto;
        position: fixed;
        z-index: 100;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 50%);
    }

    .loader {
        > img {
            @include lib-loading-mask();
        }

        > p {
            display: none;
        }
    }
}

body {
    > .loading-mask {
        z-index: $loader-overlay__z-index;
    }
}

._block-content-loading {
    position: relative;
}

#cart-totals .loading-mask {
    top: -10px;
    right: -30px;
    bottom: -20px;
    left: -30px;
}
