/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//
//  _import imports all appearance scss files
//  _____________________________________________

@import 'default';
@import 'poster';
@import 'collage-center';
@import 'collage-left';
@import 'collage-right';
