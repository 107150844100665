/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//
//  _import imports all content types less files
//  _____________________________________________

@import 'banner/import';
@import 'block/import';
@import 'buttons/import';
@import 'button-item/import';
@import 'column/import';
@import 'column-group/import';
@import 'heading/import';
@import 'html/import';
@import 'image/import';
@import 'map/import';
@import 'products/import';
@import 'row/import';
@import 'slide/import';
@import 'slider/import';
@import 'tabs/import';
@import 'text/import';
@import 'video/import';
