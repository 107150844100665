/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//
//  Variables
//  _____________________________________________

$active-nav-indent: 54px !default;

.panel.header {
    .links,
    .switcher {
        display: none;
    }
}

.nav-toggle {
    @include lib-icon-text-hide();
    z-index: 100;
    cursor: pointer;
    display: block;
    left: 15px;
    line-height: 16px;
    position: absolute;
    background-repeat: no-repeat;
    background-position: left center;
    text-transform: uppercase;
    top: 50%;
    margin-top: -9px;

    @include max-screen($screen__m) {
        margin-top: -15px;
    }

    svg.menu-stripes {
        vertical-align: -4px;
        margin-right: 9px;
        fill: $color-black;
    }

    svg.menu-close {
        display: none;
        vertical-align: -2px;
        margin: 0 9px 0 5px;
        fill: $color-white;
    }

    .close {
        display: none;
        font-size: 11px;
        color: $color-white;
    }

    &:hover {
        svg.menu-stripes {
            fill: rgba($color-black, 0.5);
        }

        .menu {
            color: rgba($color-black, 0.5);
        }
    }

    .menu {
        font-size: 0;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    @include min-screen($screen__m) {
        left: 35px;

        .menu {
            font-size: 11px;
        }
    }
}

.cms-home .nav-toggle:not(:hover) {
    svg.menu-stripes {
        fill: $color-white;
    }
    color: $color-white;
}

.nav-sections {
    overflow-scrolling: touch;
    transition: left 0.3s;
    height: 100%;
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    .switcher {
        font-size: 1.6rem;
        font-weight: 700;
        margin: 0;
        padding: 0.8rem 3.5rem 0.8rem 2rem;

        .label {
            display: block;
            margin-bottom: $indent__xs;
        }
    }

    .switcher-trigger {
        strong {
            position: relative;
        }
    }

    .switcher-dropdown {
        @include lib-list-reset-styles();
        padding: $indent__s 0;
    }

    .header.links {
        @include lib-list-reset-styles();
        border-bottom: 1px solid $color-gray-light7;

        li {
            font-size: 1.6rem;
            margin: 0;

            &.greet.welcome {
                border-top: 1px solid $color-gray-light7;
                font-weight: 700;
                padding: 0.8rem $indent__base;
            }

            > a {
                border-top: 1px solid $color-gray-light7;
            }
        }

        a,
        a:hover {
            @include lib-css(color, $navigation-level0-item__color);
            @include lib-css(text-decoration, $navigation-level0-item__text-decoration);
            display: block;
            font-weight: 700;
            padding: 0.8rem $indent__base;
        }

        .header.links {
            border: 0;
        }
    }

    &.main-menu {
        display: none;
        background-color: $color-brownie1;

        .navigation {
            max-width: none;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 120px;

            @include max-screen($screen__m) {
                position: fixed;
                top: 90px;
                overflow: auto;
            }

            ul {
                padding: 0;
            }

            .menu-main-ul {
                max-width: $screen__xl;
                position: relative;
                padding: 0;
                margin: 0 auto;
                height: 100%;

                @include max-screen($screen__m) {
                    padding-top: 100px;
                }

                li {
                    margin: 0;
                }

                .top-block {
                    font-size: 0;
                    left: 0;
                    right: 0;

                    @include min-screen($screen__m) {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    .menu-column {
                        display: inline-block;
                        font-size: 13px;
                        vertical-align: top;
                        text-align: center;
                        padding: 0 15px;
                        box-sizing: border-box;
                        width: 100%;

                        @include min-screen($screen__m) {
                            width: 33.3%;
                        }

                        > span {
                            color: $color-white;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 26px;
                            margin-bottom: 15px;
                            padding-bottom: 10px;
                            display: inline-block;
                            border-bottom: 1px solid rgba($color-white, 0.5);
                        }

                        @include max-screen($screen__m) {
                            text-align: left;
                            margin-bottom: 30px;

                            > span {
                                margin-bottom: 15px;
                            }
                        }

                        ul li {
                            font-size: 14px;
                            line-height: 22px;
                            margin-bottom: 15px;
                            font-weight: 500;
                        }

                        ul.level3.submenu {
                            padding-top: 15px;

                            li {
                                font-size: 12px;
                                font-weight: 400;
                                margin-bottom: 5px;
                            }
                        }
                    }
                }

                .bottom-block {
                    text-align: center;
                    padding-bottom: 55px;
                    width: 100%;
                    z-index: 1;

                    @include min-screen($screen__m) {
                        position: absolute;
                        bottom: 0;
                    }

                    ul {
                        li {
                            display: inline-block;
                            margin: 0 30px;

                            a {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 22px;
                                color: rgba($color-white, 0.5);

                                &:hover {
                                    color: $color-white;
                                }
                            }
                        }
                    }
                }
            }
        }

        .menu-top-links {
            @include min-screen($screen__m) {
                display: none;
            }

            position: absolute;
            top: 21px;
            right: 10px;

            &.extra-margin {
                right: 63px;
            }

            ul {
                padding: 0;

                li {
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 10px;

                    &.link.my-account-link a svg.dummy,
                    &.link.wishlist a svg.heart {
                        fill: $color-white;
                    }
                }
            }
        }

        .top-block,
        .middle-block,
        .bottom-block {
            > span {
                display: none;
            }
        }

        a {
            color: $color-white;

            &:hover {
                text-decoration: none;
                color: rgba($color-white, 0.5);
            }
        }

        .switcher.switcher-language {
            position: absolute;
            padding: 0;
            margin: 0;
            top: 30px;
            right: 30px;
            width: 20px;
            height: 20px;
            border: 1.5px solid $color-white;
            overflow: hidden;
            border-radius: 50%;
            display: block;

            @include max-screen($screen__m) {
                top: 22px;
            }

            ul {
                margin: 0;
                padding: 0;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                li.lang {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    list-style: none;
                    background-position: center;
                    background-repeat: no-repeat;

                    &.current {
                        z-index: 0;
                        opacity: 1;
                    }

                    &.switcher-option {
                        z-index: 1;
                        opacity: 0;

                        a {
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                        }
                    }

                    &:hover {
                        &.switcher-option {
                            transition: all 0.1s;
                            opacity: 1;
                        }
                    }

                    &.view-pt_en {
                        background-image: url('../media/flags/pt_en.svg');
                    }

                    &.view-pt {
                        background-image: url('../media/flags/pt.svg');
                    }

                    .label {
                        font-size: 0;
                    }
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

.navigation {
    padding: 0;

    .parent {
        .level-top {
            position: relative;
            @include lib-icon-font(
                $_icon-font-content  : $icon-down,
                $_icon-font-size     : 42px,
                $_icon-font-position : after,
                $_icon-font-display  : block
            );

            &:after {
                position: absolute;
                right: 7px;
                top: -8px;
            }

            &.ui-state-active {
                @include lib-icon-font-symbol(
                    $_icon-font-content  : $icon-up,
                    $_icon-font-position : after
                );
            }
        }
    }
}

.nav-before-open {
    height: 100%;
    overflow-x: hidden;
    width: 100%;

    .page-wrapper {
        @include lib-css(transition, left 0.3s, 1);
        height: 100%;
        left: 0;
        overflow: hidden;
        position: relative;
    }

    body {
        height: 100%;
        overflow: hidden;
        position: relative;
        width: 100%;
    }
}

.nav-open {
    .nav-sections {
        left: 0;
        z-index: 99;
    }

    .main-menu {
        display: block;
    }

    .nav-toggle {
        svg.menu-stripes,
        .menu {
            display: none;
        }

        svg.menu-close,
        .close {
            display: inline-block;
        }


        .close {
            @include max-screen($screen__m) {
                display: none;
            }
        }
    }
    .cms-home .nav-toggle:not(:hover),
    .nav-toggle:not(:hover) {
        svg.menu-close {
            display: inline-block;
        }
    }

    .logo {
        svg {
            .logo-bg {
                transition: fill 0.1s ease;
                fill: $color-white;
            }

            .flames {
                transition: fill 0.1s ease;
                fill: $color-brownie1;
            }

            .logo-text {
                transition: fill 0.1s ease;
                fill: $color-white;
            }
        }
    }

    .candle-in-slider .slide .slide-content,
    .candle-in-slider .slider-nav,
    .header.panel > .header.links,
    .minicart-wrapper {
        display: none;
    }
}

.nav-sections-items {
    @include lib-clearfix();
    position: relative;
    z-index: 1;
}

.nav-sections-item-title {
    background: darken($navigation__background, 5%);
    border: solid darken($navigation__background, 10%);
    border-width: 0 0 1px 1px;
    box-sizing: border-box;
    float: left;
    height: 71px;
    padding-top: 24px;
    text-align: center;
    width: 33.33%;

    &.active {
        background: transparent;
        border-bottom: 0;
    }

    .nav-sections-item-switch {
        &:hover {
            text-decoration: none;
        }
    }
}

.nav-sections-item-content {
    @include lib-clearfix();
    box-sizing: border-box;
    float: right;
    margin-left: -100%;
    margin-top: 71px;
    width: 100%;

    &.active {
        display: block;
    }

    padding: $indent__m 0;
}

.nav-sections.main-menu .create-candle-in,
.create-candle-in {
    text-align: center;
    position: relative;
    margin: 5px;
    display: block;

    a {
        font-size: 11px;
        line-height: 16px;
        text-transform: uppercase;
        margin: 50px 0;
        padding: 18px 30px;
        letter-spacing: 1px;
        outline: 1px solid $color-white;
        border: 1px solid $color-brownie1;
        color: $color-brownie1;
        background-color: $color-white;
        display: inline-block;
        text-decoration: none;

        &:hover {
            color: $color-white;
            border-color: $color-white;
            background-color: $color-brownie1;
            outline-color: $color-brownie1;
        }
    }
}

.nav-sections.main-menu .create-candle-in {
    a {
        @include max-screen($screen__m) {
            margin-top: 0;
        }
    }

    @include max-screen($screen__m) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
}

//@include lib-main-navigation();

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .nav-toggle {
        display: block;
    }

    .nav-sections {
        @include lib-vendor-prefix-flex-shrink(0);
        @include lib-vendor-prefix-flex-basis(auto);
        margin-bottom: $indent__m;
    }

    .nav-sections-item-title {
        display: none;
    }

    .nav-sections-item-content {
        display: block !important;
    }

    .nav-sections-item-content > * {
        display: none;
    }

    .nav-sections-item-content {
        > .navigation {
            display: block;
        }
    }

    //@include lib-main-navigation-desktop();

    .panel.header {
        .links,
        .switcher {
            display: inline-block;
        }
    }
}
