/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.candle-in-columns-block-widget {
    font-size: 0;
    margin: 50px 0;
    @include wide-gray-section();

    .title {
        @include wide-gray-section-title();
    }

    .item-title {
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        text-align: center;
    }


    .column {
        padding: 15px;
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        vertical-align: top;
        box-sizing: border-box;

        &:before {
            content: "";
            display: block;
            position: relative;
            height: 30px;
            background-position: center;
            background-repeat: no-repeat;
        }

        &.durability:before {
            background-image: url('../CandleIn_Theme/media/durability.svg');
        }

        &.quality:before {
            background-image: url('../CandleIn_Theme/media/quality.svg');
        }

        &.security:before {
            background-image: url('../CandleIn_Theme/media/security.svg');
        }
    }

    .columns.total-1 .column {
        width: 100%;
    }

    .columns.total-2 .column {
        width: 50%;
    }

    .columns.total-3 .column {
        width: 33.33%;
    }

    .columns.total-4 .column {
        width: 25%;
    }

    .columns.total-5 .column {
        width: 20%;
    }

    .columns.total-6 .column {
        width: 16.6667%;
    }

    .link-block {
        text-align: center;
        margin: 50px 0 30px;

        .link {
            display: inline-block;
            border: 1px solid $color-gray69;
            font-size: 11px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: 1px;
            line-height: 16px;
            text-transform: uppercase;
            text-decoration: none;
            color: $color-black;
            padding: 15px 30px;
        }
    }
}
