/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//
//  Actions-toolbar
//  _____________________________________________

@mixin lib-actions-toolbar(
    $_actions-toolbar-actions-position                   : $actions-toolbar-actions__position,
    $_actions-toolbar-actions-reverse                    : $actions-toolbar-actions__reverse,
    $_actions-toolbar-margin                             : $actions-toolbar__margin,
    $_actions-toolbar-padding                            : $actions-toolbar__padding,
    $_actions-toolbar-actions-margin                     : $actions-toolbar-actions__margin,
    $_actions-toolbar-primary-actions-margin             : $actions-toolbar-actions-primary__margin,
    $_actions-toolbar-secondary-actions-margin           : $actions-toolbar-actions-secondary__margin,
    $_actions-toolbar-actions-links-margin-top           : $actions-toolbar-actions-links__margin-top,
    $_actions-toolbar-primary-actions-links-margin-top   : $actions-toolbar-actions-links-primary__margin-top,
    $_actions-toolbar-secondary-actions-links-margin-top : $actions-toolbar-actions-links-secondary__margin-top
) {
    @include lib-clearfix();
    @include lib-css(margin, $_actions-toolbar-margin);
    @include lib-css(padding, $_actions-toolbar-padding);
    @include _lib-actions-toolbar-align($_actions-toolbar-actions-position);
    @include _lib-actions-toolbar-floats($_actions-toolbar-actions-position, $_actions-toolbar-actions-reverse);
    .primary,
    .secondary {
        display: inline-block;

        .action {
            @include lib-css(margin, $_actions-toolbar-actions-margin);
        }

        a.action {
            display: inline-block;
            @include lib-css(margin-top, $_actions-toolbar-actions-links-margin-top);
        }
    }

    .primary {
        .action {
            @include lib-css(margin, $_actions-toolbar-primary-actions-margin);
        }

        a.action {
            @include lib-css(margin-top, $_actions-toolbar-primary-actions-links-margin-top);
        }
    }

    .secondary {
        margin-top: 30px;

        .action {
            @include lib-css(margin, $_actions-toolbar-secondary-actions-margin);
        }

        a.action {
            @include lib-css(margin-top, $_actions-toolbar-secondary-actions-links-margin-top);
        }
    }
}

//
//  Actions-toolbar-clear-floats
//  ---------------------------------------------

@mixin lib-actions-toolbar-clear-floats() {
    .primary,
    .secondary {
        display: block;
        float: none;
    }
}

//
//  Internal use mixins
//  ---------------------------------------------

@mixin _lib-actions-toolbar-align($_actions-toolbar-actions-position) {
    @if $_actions-toolbar-actions-position != justify {
        @include lib-css(text-align, $_actions-toolbar-actions-position);
    }
}

@mixin _lib-actions-toolbar-floats(
    $_actions-toolbar-actions-position,
    $_actions-toolbar-actions-reverse
) {
    @if $_actions-toolbar-actions-position == justify {
        @if $_actions-toolbar-actions-reverse {
            .primary {
                float: right;
            }
            .secondary {
                float: left;
            }
        }
        @else {
            .primary {
                float: left;
            }
            .secondary {
                float: right;
            }
        }
    }
    @else if $_actions-toolbar-actions-position == left {
        @if $_actions-toolbar-actions-reverse {
            .secondary {
                float: left;
            }
        }
        @else {
            .primary {
                float: left;
            }
        }
    }
    @else if $_actions-toolbar-actions-position == right {
        @if $_actions-toolbar-actions-reverse {
            .primary {
                float: right;
            }
        }
        @else {
            .secondary {
                float: right;
            }
        }
    }
    @else if $_actions-toolbar-actions-position == center {
        .primary,
        .secondary {
            vertical-align: top;
        }
    }
}
