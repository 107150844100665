/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.block-product-link,
.block-category-link {
    &.widget {
        display: block;
        margin-bottom: $indent__base;
    }
}

.block-product-link-inline {
    &.widget {
        margin: 0;
    }
}

.block.widget {
    .product-item-info {
        width: auto;
    }

    .pager {
        padding: 0;

        .toolbar-amount {
            float: none;
            @include lib-font-size(12);
        }

        .pages-item-previous {
            padding-left: 0;
        }

        .pages-item-next {
            position: relative;
        }

        .items {
            white-space: nowrap;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include min-screen($screen__s) {
    .block.widget .products-grid .product-item,
    .page-layout-1column .block.widget .products-grid .product-item,
    .page-layout-3columns .block.widget .products-grid .product-item {
        width: (100% / 3);
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block.widget .products-grid .product-item {
        width: (100% / 3);

        .sidebar & {
            margin-left: 0;
            width: 100%;

            .actions-secondary {
                display: block;
                padding: 10px 0;
            }
        }
    }

    .page-layout-1column .block.widget .products-grid .product-item {
        width: (100% / 4);
    }

    .page-layout-3columns .block.widget .products-grid .product-item {
        width: (100% / 2);
    }
}

@include min-screen($screen__l) {
    .block.widget .products-grid .product-item {
        width: (100% / 5);
    }

    .page-layout-1column .block.widget .products-grid .product-item {
        margin-left: calc((100% - 5 * (100% / 6)) / 4);
        width: (100% / 6);

        &:nth-child(4n + 1) {
            margin-left: calc((100% - 5 * (100% / 6)) / 4);
        }

        &:nth-child(5n + 1) {
            margin-left: 0;
        }
    }

    .page-layout-3columns .block.widget .products-grid .product-item {
        width: (100% / 4);
    }

    .block.widget .products-grid .product-items {
        margin: 0;
    }

    .block.widget .products-grid .product-item {
        margin-left: calc((100% - 4 * 23.5%) / 3);
        padding: 0;
        width: 23.5%;

        &:nth-child(4n + 1) {
            margin-left: 0;
        }
    }
}

@include min-screen($screen__m) {
    .sidebar .block.widget .pager {
        .item:not(.pages-item-next):not(.pages-item-previous) {
            @extend .abs-no-display-desktop;
        }

        .pages-item-next {
            padding: 0;

            .action {
                margin: 0;
            }
        }
    }
}
