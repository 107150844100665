/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.fotorama__grab {
    .fotorama__wrap & {
        @extend .fotorama-grab-cursor;
    }
}
