/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.fotorama__video-close {
    &.fotorama-show-control {
        opacity: 1;
        transform: translate3d(0, -10px, 0);
    }
}
