/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.fotorama__wrap {
    @extend .fotorama-no-select;
    position: relative;
    direction: ltr;
    text-size-adjust: 100%;
    z-index: 0;
    margin: 0 auto;

    .fotorama--fullscreen & {
        max-width: 100% !important;
    }

    &--pan-y {
        touch-action: pan-y;
    }
}
