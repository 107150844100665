/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//
//  Default appearance styles
//  _____________________________________________

[data-content-type='map'] {
    box-sizing: border-box;
    height: 300px;
}
