/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m - 1) {
    .pagebuilder-column-group {
        flex-wrap: wrap;
    }
}
