/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.collage-tip {
    text-align: center;

    .tip-container {
        display: inline-block;
        position: relative;
        margin: 0 0 15px;
        background-color: rgba($color-purple, 0.1);
        mix-blend-mode: normal;
        border-radius: 8px;
        color: $color-purple;
        cursor: pointer;
        padding: 17px 45px;
        background-image: url('../media/tip.svg');
        background-repeat: no-repeat;
        background-position: 15px center;
        text-align: left;
        font-size: 12px;
        line-height: 16px;
        font-weight: 300;

        .close {
            position: absolute;
            right: 15px;
            top: 50%;
            margin-top: -9px;
            font-weight: 100;
            font-size: 18px;
        }
    }
}

.search-box {
    margin-bottom: 20px;
    text-align: center;

    input {
        color: $color-gray-light8;

        @include min-screen($screen__s) {
            max-width: 50%;
        }
    }

    .categories {
        ul {
            padding: 0;
            font-size: 0;
            width: 480px;

            @include max-screen($screen__l) {
                width: auto;
            }

            li {
                list-style: none;
                width: auto;
                display: inline-block;
                padding: 5px 10px;
                box-sizing: border-box;

                input {
                    display: none;
                }

                label {
                    position: relative;
                    display: inline-block;
                    color: $color-gray-light8;
                    border: 1px solid $color-gray-light7;
                    padding: 15px 15px 15px 50px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 22px;
                    text-align: center;
                    cursor: pointer;
                    min-width: 60px;
                    box-sizing: border-box;
                    width: 100%;
                    border-radius: 5px;
                    text-align: left;

                    &:hover {
                        border-color: rgba($color-brownie1, .2);
                    }

                    .thumbnail {
                        position: absolute;
                        top: 10px;
                        left: 10px;
                        width: 32px;
                        height: 32px;
                        border: 1px solid $color-gray-light7;
                        background-color: $color-white;

                        .interior {
                            border-radius: 50%;
                            position: absolute;
                            top: 3px;
                            right: 3px;
                            bottom: 3px;
                            left: 3px;
                        }

                        img {
                            position: absolute;
                            top: 3px;
                            right: 3px;
                            bottom: 3px;
                            left: 3px;
                            width: 26px
                        }
                    }
                }

                input[type="radio"]:checked+.label {
                    color: $color-black;
                    border-color: $color-brownie1;
                    background-image: linear-gradient(135deg, rgba($color-brownie1, .2) 7.14%, $color-white 7.14%, $color-white 50%, rgba($color-brownie1, .2)  50%, rgba($color-brownie1, .2)  57.14%, $color-white 57.14%, $color-white 100%);
                    background-size: 5px 5px;
                }
            }
        }
    }
}

ul.board-images {
    padding:0;
    font-size: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px 20px;

    &.empty {
        padding: 100px 0;
        background-image: url('../media/photos.svg');
        background-repeat: no-repeat;
        background-position: center;
    }

    li {
        display: inline-block;
        width: 50%;
        padding-bottom: 50%;
        margin: 0;
        box-sizing: border-box;
        position: relative;

        .thumbnail {
            border: 2px solid $color-gray-light7;
            position: absolute;
            cursor: pointer;
            top: 5px;
            right: 5px;
            bottom: 5px;
            left: 5px;

            .img-container {
                position: absolute;
                top: 3px;
                right: 3px;
                bottom: 3px;
                left: 3px;

                img {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform:translate(-50%, -50%);
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }

        &.selected {
            .thumbnail {
                border-color: $color-brownie1;

                img {
                    opacity: .5;
                }

                &:after {
                    content:"";
                    display: block;
                    position: absolute;
                    top: 6px;
                    right: 6px;
                    width: 15px;
                    height: 15px;
                    background-image: url('../media/thumb-check.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
        }
    }
}
