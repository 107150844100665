/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.header.panel > .header.links > .link.create-a-candle-in-link {
    @include max-screen($screen__l) {
        display: none;
    }

    a {
        font-size: 11px;
        font-weight: 700;
        letter-spacing: 1px;
        width: auto;
        text-decoration: none;
        color: $color-black;
        outline: 1px solid rgba($color-black, .5);
        border: 1px solid $color-white;
        text-transform: uppercase;
        line-height: 30px;
        height: 30px;
        margin-top: -5px;
        //border-radius: 15px;
        padding: 0 15px;
        margin-right: 5px;

        &:hover {
            transition: 0.2s;
            background-color: $color-brownie1;
            border: 1px solid $color-white;
            color: $color-white;
        }
    }
}
