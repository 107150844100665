/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

@import 'fotorama/variables';
@import 'fotorama/mixins';
@import 'fotorama/extends';

@import 'fotorama/active';
@import 'fotorama/arr';
@import 'fotorama/arr__arr';
@import 'fotorama/caption';
@import 'fotorama/caption__wrap';
@import 'fotorama/dot';
@import 'fotorama/fade';
@import 'fotorama/fotorama';
@import 'fotorama/fullscreen';
@import 'fotorama/fullscreen-icon';
@import 'fotorama/gallery-placeholder';
@import 'fotorama/grab';
@import 'fotorama/grabbing';
@import 'fotorama/html';
@import 'fotorama/img';
@import 'fotorama/load';
@import 'fotorama/magnifier';
@import 'fotorama/magnify';
@import 'fotorama/nav';
@import 'fotorama/nav-wrap';
@import 'fotorama/nav__frame';
@import 'fotorama/nav__shaft';
@import 'fotorama/no-select';
@import 'fotorama/pointer';
@import 'fotorama/select';
@import 'fotorama/shadow';
@import 'fotorama/shadows';
@import 'fotorama/show-control';
@import 'fotorama/spinner';
@import 'fotorama/stage';
@import 'fotorama/stage__frame';
@import 'fotorama/stage__shaft';
@import 'fotorama/thumb';
@import 'fotorama/thumb-border';
@import 'fotorama/thumb__arr';
@import 'fotorama/vertical_ratio';
@import 'fotorama/video';
@import 'fotorama/video-close';
@import 'fotorama/video-play';
@import 'fotorama/wrap';
@import 'fotorama/zoom';
