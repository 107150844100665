/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.personalize-index-index.personalize-menu-open .page-header {
    display: none;
}

.personalize-navigation-container {
    position: relative;
    padding: 0;
    background-color: $color-white;
    border: 1px solid $color-gray-light7;
    border-width: 1px 0;
    cursor: pointer;
    z-index: 1;

    .nav-buttons {
        position: absolute;
        height: 100%;
        min-width: 100px;
        max-height: 100px;
        top: 0;
        right: 0;
        font-size: 0;
        border-left: 1px solid $color-gray-light7;
        margin: 0;

        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 1px;
            height: 100%;
            left: 50%;
            top: 0;
            background-color: $color-gray-light7;
        }

        .nav-button {
            width: 50%;
            height: 100%;
            display: inline-block;
            background-repeat: no-repeat;
            background-position: center;
            background-color: unset;
            padding: 0;
            border: 0;
            margin: 0;

            &.prev {
                background-image: url('../media/prev.svg');
            }

            &.next {
                background-image: url('../media/next.svg');
            }
        }
    }

    .nav-items {
        background-image: url('../media/nav.svg');
        background-repeat: no-repeat;
        background-position: 15px center;
        margin: 0;
        padding: 0 100px 0 50px;

        li {
            display: none;
            margin: 0;
            padding: 0;
            position: relative;

            &.nav-item {
                a {
                    text-decoration: none;
                    color: $primary__color;
                    display: block;
                    padding: 15px 0;
                    pointer-events: none;
                    text-transform: uppercase;
                    font-size: 11px;
                    font-weight: bold;

                    &.dragging {
                        opacity: 0.2;
                    }

                    &.dragging-over:not(.dragging) {
                        background-color: $color-brownie2;
                    }
                }

                &.selected {
                    display: block;
                }

                &.completed a {
                    background-image: url('../media/complete-check.svg');
                    background-repeat: no-repeat;
                    background-position: right 15px center;
                }

                &.wip a {
                    background-image: url('../media/wip.svg');
                    background-repeat: no-repeat;
                    background-position: right 15px center;
                }
            }

            &.disabled {
                opacity: 0.5;
                pointer-events: none;
                filter: grayscale(100%);
                cursor: default;

                a {
                    pointer-events: none !important;
                }
            }

            &.draggable {
                a:before {
                    content: '';
                    display: inline-block;
                    background-image: url("../images/draggable.png");
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 16px;
                    height: 16px;
                    cursor: ew-resize;
                    padding: 0 5px;
                    vertical-align: -4px;
                    opacity: 0.2;

                    @include max-screen($screen__l) {
                        cursor: ns-resize;
                        display: none;
                    }
                }
            }
        }

        .restart {
            button {
                color: $color-brownie1;
                margin: 0 auto;
                background: none;
                border: 0;
                padding: 10px 25px 10px 15px;
                font-size: 12px;
                font-weight: 400;
                background-image: url('../media/reload.svg');
                background-repeat: no-repeat;
                background-position: right center;
            }
        }
    }

    .close-menu {
        display: none;
        position: absolute;
        background-color: unset;
        background-image: url('../media/close.svg');
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 50px;
        border: none;
    }

    @include min-screen($screen__l) {
        .nav-buttons {
            display: none;
        }

        .nav-items {
            padding-right: 0;
        }
    }

    &.menu-open {
        @include max-screen($screen__l) {
            .nav-items li.draggable a {
                &:before {
                    display: inline-block;
                }
            }
        }

        @include max-screen($screen__l - 1) {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            cursor: default;

            .nav-items {
                background-image: none;
                padding: 60px 30px 0;

                li {
                    display: block;
                    border-bottom: 1px solid $color-gray-light7;

                    a {
                        pointer-events: all;
                    }

                    &.selected {
                        border-bottom: 5px solid $color-black;
                    }

                    &.restart {
                        border-bottom: none;
                        text-align: center;

                        button {
                            margin: 25px auto;
                        }
                    }
                }
            }

            .nav-buttons {
                display: none;
            }

            .close-menu {
                display: block;
            }
        }
    }

    @include min-screen($screen__l) {
        border-top: none;
        cursor: default;
        overflow: hidden;
        position: absolute;
        left: 0;
        right: 0;

        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
            height: 50px;
            top: -50px;
        }

        .nav-items {
            background-image: none;
            padding-left: 0;
            font-size: 0;
            display: flex;

            li {
                display: inline-flex;
                width: 16.6667%;
                font-size: 11px;
                text-align: center;

                &.selected {
                    display: inline-flex;

                    &:after {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 5px;
                        background-color: $color-black;
                    }
                }

                &.completed a,
                &.on-going a {
                    padding-left: 20px;
                }

                &.nav-item,
                &.restart {
                    a {
                        display: inline-block;
                        margin: 0 auto;
                        white-space: nowrap;
                        pointer-events: all;
                        padding-right: 40px;
                        padding-left: 40px;
                    }
                }
            }
        }
    }
}
