/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

@include lib-font-face(
    $family-name: $icons__font-name,
    $font-path  : $icons__font-path,
    $font-weight: normal,
    $font-style : normal
);
