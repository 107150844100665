/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//  Newsletter subscription
.column.main .block.newsletter {
    margin-bottom: $indent__xl;

    .intro {
        margin-bottom: 15px;
    }

    .form.subscribe {
        display: table;
        width: 100%;
    }

    .fieldset {
        display: table-cell;
        margin: 0;
        padding: 0;
        vertical-align: top;
    }

    .field {
        margin: 0;

        .control {
            @include lib-icon-font(
                $icon-envelope,
                $_icon-font-size        : 35px,
                $_icon-font-line-height : 33px,
                $_icon-font-color       : $form-element-input-placeholder__color
            );
            display: block;

            &:before {
                position: absolute;
            }
        }
    }

    input {
        padding-left: 35px;
    }

    .title strong {
        display: block;
        font-family: $font-family__headings;
        margin: 60px 0;
        font-size: 56px;
        line-height: 62px;

        @include max-screen($screen__m) {
            border-bottom: 1px solid $color-gray-light8;
            padding-bottom: 30px;
            margin: 30px 0;
            font-size: 26px;
            line-height: 32px;
        }
    }

    .label {
        @extend .abs-visually-hidden;
    }

    .actions {
        display: table-cell;
        vertical-align: top;
        width: 1%;

        .action.subscribe {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            margin-left: -1px;
        }

        @include max-screen($screen__m) {
            display: block;
            margin-top: 15px;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block.newsletter {
        width: 32%;
        min-width: 500px;

        .field {
            margin-right: 5px;

            .control {
                width: 100%;
            }
        }
    }
}
