/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//
//  Collage left appearance styles
//  _____________________________________________

[data-appearance='collage-left'] {
    .pagebuilder-slide-wrapper {
        .pagebuilder-overlay {
            margin-right: auto;
        }
    }
}
