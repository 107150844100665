/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.fotorama__pointer {
    .fotorama__wrap & {
        cursor: pointer;
    }
}
