/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//
//  Shopping cart
//  ---------------------------------------------


//  Summary block
.column.main .cart-summary {
    @extend .abs-add-box-sizing;
    @extend .abs-adjustment-incl-excl-tax;
    @include lib-css(background, $sidebar__background-color);
    margin-bottom: $indent__m;
    padding: 0 30px 30px;
    color: $color-white;
    font-size: 14px;

    @include max-screen($screen__m) {
        padding-top: 10px;
    }

    > .title {
        display: block;
        @include lib-heading(h3);
        text-transform: none;

        &.summary {
            font-family: $font-family-name__headings;
            font-size: 28px;
            line-height: 40px;
        }
    }

    .block {
        @extend .abs-discount-block;
        margin-bottom: 0;

        > .title {
            border-top: 1px solid $color-gray-light8;
            border-width: 1px 0;
            margin: 20px 0;
            padding: 20px 0;

            strong {
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
            }

            &:after {
                top: 20px;
                content: "▾";
                right: 2px;
                font-size: 14px;
            }
        }

        &.active > .title:after {
            transform: rotate(90deg);
            content: "▾";
        }

        .item-options {
            margin-left: 0;
        }

        .fieldset {
            margin: 15px 0 $indent__m 0;

            .field {
                margin: 0 0 $indent__s;

                &.note {
                    font-size: $font-size__s;
                }

                &:not(.choice) > .label {
                    font-weight: normal;
                    font-size: 11px;
                }

                input[type="text"] {
                    border: none;
                }
            }

            .methods {
                .field {
                    > .label {
                        display: inline;
                    }
                }
            }
        }

        .fieldset.estimate {
            > .legend,
            > .legend + br {
                @extend .abs-no-display;
            }
        }
    }
    .actions-toolbar {
        > .primary {
            button {
                @extend .abs-revert-secondary-color;
                font-size: 14px;
                line-height: 22px;
                font-weight: 400;
                background-color: unset;
                padding: 10px 25px;
                margin: 0;
                border-width: 1px;
                border-style: solid;
                color: $color-brownie1;
                border-color: rgba($color-brownie1, 0.5);

                &:hover {
                    color: lighten($color-brownie1, 15%) ;
                    border-color: $color-brownie1;
                    background-color: unset;
                }
            }
        }
    }

    select,
    input[type="text"] {
        font-size: 14px;
    }

    .block.shipping {

        .fieldset.rate {
            margin-bottom: 15px;
        }

        .items.methods {
            display: block;
            position: relative;

            .item-title {
                padding-left: 30px;
                float: left;
                margin-right: 10px;

            }

            .item-options {
                display: block;
                margin-bottom: 20px;
                height: 20px;

                .radio {
                    position: absolute;
                    left: 0;
                    margin-top: 1px;
                }

                .label {
                    position: absolute;
                    right: 0;
                }
            }
        }
    }
}

//  Totals block
.cart-totals {
    @extend .abs-sidebar-totals;
    border-top: 1px solid $color-gray-light8;
    padding-top: $indent__s;
    margin-top: 10px;

    .amount {
        font-weight: bold;
        font-size: 11px;
        line-height: 16px;
    }

    .mark {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 11px;
        line-height: 16px;
        padding: 10px 0;

        .value {
            display: block;
            font-weight: 400;
            font-size: 11px;
            line-height: 18px;
        }
    }

    .grand .amount strong,
    .grand.totals .mark strong {
        font-weight: bold;
        font-size: 18px;
        line-height: 26px;
        text-transform: none;
    }

    .table-wrapper {
        margin-bottom: 0;
        overflow: inherit;
    }
}

//  Products table
.cart.table-wrapper {
    margin-bottom: 30px;

    .items {
        thead + .item {
            border-top: $border-width__base solid $border-color__base;
        }

        > .item {
            border-bottom: $border-width__base solid $border-color__base;
            position: relative;
        }
    }

    .col {
        padding: 0 0 15px;
        font-weight: bold;
        font-size: 11px;
        line-height: 16px;
        text-transform: uppercase;

        .price {
            font-weight: normal;
        }

        &.subtotal .price {
            font-weight: 700;
        }

        &.qty {
            .input-text {
                @extend .abs-input-qty;
                margin-top: -10px;
                width: 60px;
                font-weight: 300;
                font-size: 14px;
            }

            .label {
                @extend .abs-visually-hidden;
            }
        }
    }

    .item {
        &-actions td {
            padding-bottom: $indent__s;
            text-align: center;
            white-space: normal;
            padding: 0;
        }

        dt,
        dd {
            font-weight: 400;
            text-transform: none;
        }

        dt {
            font-weight: bold;
            display: none;
        }

        dd {
            margin-right: 10px;
        }

        .col {
            &.item {
                display: block;
                min-height: 75px;
                position: relative;
            }
        }
    }

    .actions-toolbar {
        @extend .abs-add-clearfix;
    }

    .action {
        &.help.map {
            @extend .abs-action-button-as-link;
            font-weight: $font-weight__regular;
        }
    }

    .product {
        &-item-photo {
            display: block;
            left: 0;
            padding: 0;
            position: absolute;
            top: 15px;
            width: 100%;
        }

        &-item-details {
            white-space: normal;

            @include max-screen($screen__m) {
                padding-left: 125px;
            }
        }

        &-item-name {
            display: inline-block;
            font-weight: $font-weight__bold;
            font-size: 18px;
            line-height: 26px;
            text-transform: none;

            a {
                text-decoration: none;
            }
        }
    }

    .gift-registry-name-label {
        &:after {
            content: ':';
        }
    }

    //  Product options
    .item-options {
        @extend .abs-product-options-list;
        @extend .abs-add-clearfix;
        font-size: $font-size__s;
        margin-bottom: $indent__s;

        dt:after {
            content:"";
        }
    }

    .product-item-name + .item-options {
        margin-top: $indent__s;
        font-size: 11px;
    }

    .product-image-wrapper {
        width: 110px;
        @extend .abs-reset-image-wrapper;
    }

    .action.configure {
        display: inline-block;
        margin: 0 0 $indent__base;
    }
}

.cart-container {
    .form-cart {
        @extend .abs-shopping-cart-items;
    }

    .checkout-methods-items {
        @extend .abs-reset-list;
        margin-top: $indent__base;
        text-align: center;

        .action.primary {
            @extend .abs-button-l;
            width: 100%;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
        }

        .item {
            & + .item {
                margin-top: $indent__base;
            }
        }
    }
}

//  Products pager
.cart-products-toolbar {
    .toolbar-amount {
        margin: $indent__m 0 15px;
        padding: 0;
        text-align: center;
    }

    .pages {
        margin: 0 0 $indent__m;
        text-align: center;

        .items {
            > .item {
                border-bottom: 0;
            }
        }
    }
}

.cart-products-toolbar-top {
    border-bottom: $border-width__base solid $border-color__base;
}

//
//  Cross sell
//  ---------------------------------------------

.block.crosssell {
    margin-top: 70px;
}

.cart-tax-info,
.cart .cart-tax-info {
    + .cart-tax-total {
        display: block;
    }
}

.cart.table-wrapper,
.order-items.table-wrapper {
    .col.price,
    .col.subtotal,
    .col.msrp {
        text-align: right;
    }

    @include min-screen($screen__m) {
        .col.qty {
            text-align: center;
            padding-left: 60px;
        }
    }

    .col {
        padding: 40px 0 30px;
    }

    thead .col {
        padding-top: 0;
        padding-bottom: 15px;
    }

    .actions-toolbar {
        margin-bottom: 15px;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .cart {
        &.table-wrapper {
            overflow: inherit;

            thead {
                .col {
                    display: none;
                }
            }

            .col {
                &.qty,
                &.price,
                &.subtotal,
                &.msrp {
                    box-sizing: border-box;
                    float: left;
                    text-align: center;
                    white-space: nowrap;
                    width: 33%;

                    &:before {
                        content: attr(data-th);
                        display: block;
                        font-weight: $font-weight__bold;
                        padding-bottom: 25px;
                    }
                }

                &.msrp {
                    white-space: normal;
                }
            }

            .item {
                .col.item {
                    padding-bottom: 0;
                }
            }
        }
    }

    .cart-container {
        .form-cart {
            @extend .abs-shopping-cart-items-mobile;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .cart-container {
        @extend .abs-add-clearfix-desktop;
        .form-cart {
            @extend .abs-shopping-cart-items-desktop;
        }

        .widget {
            float: left;
        }
    }

    .cart-summary {
        float: right;
        position: relative;
        width: 29%;
        padding: 0 30px 20px;

        .actions-toolbar {
            .column.main & {
                @extend .abs-reset-left-margin-desktop;
                > .secondary {
                    float: none;
                }
            }
        }

        .block {
            .fieldset {
                .field {
                    @include lib-form-field-type-revert($_type: block);
                    margin: 0 0 $indent__s;
                }
            }
        }
    }

    .cart {
        &.table-wrapper {
            .items { // Google Chrome version 44.0.2403.107 m fix
                min-width: 100%;
                width: auto;
            }

            .item {
                &-actions {
                    td {
                        text-align: right;
                    }
                }
            }

            .product {
                &-item-photo {
                    display: table-cell;
                    max-width: 100%;
                    padding-right: $indent__base;
                    position: static;
                    vertical-align: top;
                    width: 1%;
                }

                &-item-details {
                    display: table-cell;
                    vertical-align: top;
                    white-space: normal;
                    width: 99%;
                }
            }

            .item-actions {
                .actions-toolbar {
                    @extend .abs-reset-left-margin-desktop;
                    text-align: right;
                }
            }
        }

        .action {
            margin-bottom: $indent__s;
        }
    }

    //  Products pager
    .cart-products-toolbar {
        .toolbar-amount {
            line-height: 30px;
            margin: 0;
        }

        .pages {
            float: right;
            margin: 0 0 1px;

            .item {
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .cart.table-wrapper {
        .cart-products-toolbar {
            + .cart {
                thead {
                    tr {
                        th.col {
                            padding-bottom: 7px;
                            padding-top: 8px;
                        }
                    }
                }
            }
        }

        .cart {
            + .cart-products-toolbar {
                margin-top: $indent__m;
            }
        }
    }
}

.cart-container .form-cart .actions .action,
.cart.table-wrapper .actions-toolbar > .action {
    @extend button;
    @include lib-link-as-button();
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    background-color: unset;
    padding: 10px 25px;
    margin: 0 5px 15px;
    border-width: 1px;
    border-style: solid;
    color: $color-brownie1;
    border-color: rgba($color-brownie1, 0.5);
    border-radius: 0;

    &:hover {
        background-color: unset;
        color: $color-brownie1;
        border-color: $color-brownie1;
    }

    &:after {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        margin-left: 5px;
        vertical-align: -3px;
    }

    &.action-edit {
        &:after {
            background-image: url('../media/edit.svg');
        }

        &:hover {
            border-color: $color-brownie1;
        }
    }

    &.action-delete {
        color: $color-red10;
        border-color: rgba($color-red10, 0.5);

        &:after {
            background-image: url('../media/bin.svg');
        }

        &:hover {
            color: $color-red10;
            border-color: $color-red10;
        }
    }

    &.continue {
        &:after {
            display: none;
        }

        &:before {
            content: "";
            display: inline-block;
            width: 25px;
            height: 20px;
            background-repeat: no-repeat;
            vertical-align: -3px;
            background-image: url('../media/arrow-left.svg');
        }
    }

    &.update {
        margin-right: 0;
        &:before {
            display: none;
        }
        &:after {
            background-image: url('../media/reload.svg');
        }
    }

    &:last-child {
        margin-right: 0;
    }
}

.opc-block-rush-delivery {
    label {
        padding-left: 5px;
        vertical-align: 1px;
    }

    .price {
        float: right;
        font-weight: bold;
        font-size: 11px;
        line-height: 16px;
    }

    .description {
        font-weight: normal;
        font-size: 11px;
        line-height: 18px;
    }
}

