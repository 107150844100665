/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.block-content a.action,
a.action.primary,
label.btn,
.modal-footer button,
button.btn {
    font-size: 14px;
    line-height: 22px;
    color: $color-brownie1;
    border-color: $color-brownie-light1;
    font-weight: 400;
    background-color: unset;
    padding: 10px 25px;
    margin: 0 5px 15px;

    &.icon {
        &:after {
            content: "";
            display: inline-block;
            width: 16px;
            height: 14px;
            background-repeat: no-repeat;
            margin-left: 5px;
            vertical-align: -1px;
        }

        &.gallery:after {
            background-image: url('../media/gallery.svg');
        }

        &.folder:after {
            background-image: url('../media/folder.svg');
        }

        &.cancel {
            border: none;
            color: $error__color;

            @include min-screen($screen__xs) {
                padding-left: 0;
            }

            &:after {
                background-image: url('../media/bin.svg');
                height: 16px;
                vertical-align: -2px;
            }
        }
    }

    @include max-screen($screen__xs) {
        width: 100%;
        margin: 0 0 20px;
        padding: 15px 30px;
        box-sizing: border-box;
    }

    &:hover {
        color: $color-brownie1;
        border-color: $color-brownie1;
        background-color: unset;
    }
}

button {
    &:active {
        @include lib-css(box-shadow, $button__shadow);
    }
}

a.action.primary {
    @include lib-link-as-button();
}
.action.primary {
    @include lib-button-primary();
}

.block-content a.action {
    display: inline-block;
    text-decoration: none;
    margin: 15px 10px 0 0;
    border: 1px solid $color-brownie-light1;
}
