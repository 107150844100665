/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.fotorama__nav-wrap--vertical {
    &.fotorama__nav-wrap {
        position: absolute;
        display: inline-block;
        top: 0;
        left: 0;
    }
}
