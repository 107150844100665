/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//
//  Variables
//  _____________________________________________

$minicart__border-color       : $color-gray-light7 !default;
$minicart__padding-horizontal : $indent__base !default;

$minicart-qty__height         : 16px !default;
//
//  Minicart
//  ---------------------------------------------

.block-minicart {
    text-align: left;

    .items-total,
    .subtotal {
        font-size: 14px;
        line-height: 22px;
    }

    .items-total {
        float: left;
        font-weight: 700;
    }

    .subtotal {
        text-align: right;

        .label {
            @extend .abs-colon;
            &:after {
                display: none;
            }
        }

        .price-container {
            margin-top: 5px;
        }
    }

    .amount {
        .price-wrapper {
            &:first-child {
                .price {
                    font-size: 18px;
                    line-height: 26px;
                    font-weight: $font-weight__bold;
                }
            }
        }
    }

    .subtitle {
        display: none;

        &.empty {
            display: block;
            padding: 0px 0 5px;
            text-align: center;
            font-weight: 400;
            font-size: 12px;
            margin-bottom: 20px;
        }
    }

    .text {
        &.empty {
            text-align: center;
        }
    }

    .block-content {
        > .actions {
            text-align: center;

            &:last-of-type {
                border-top: 1px solid $color-gray-light7;
            }

            > .primary {
                margin: 0 0 20px;
                padding-top: 20px;
                border-bottom: 1px solid $color-gray-light7;

                .action {
                    &.primary {
                        @extend .abs-button-l;
                        display: block;
                        margin-bottom: 20px;
                        width: 100%;
                        padding: 15px;
                        font-size: 14px;
                        line-height: 22px;
                        font-weight: 500;
                        border-bottom: 1px solid $color-gray-light7;

                        &:focus {
                            background-color: $color-brownie1;
                            border:none;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            > .secondary {
                line-height: 16px;
                border-top: 1px solid $color-gray-light7;

                .viewcart {
                    text-transform: uppercase;
                    font-size: 11px;
                    letter-spacing: 1px;
                    font-weight: 700;
                    box-sizing: border-box;
                    border: none;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .block-category-link,
    .block-product-link,
    .block-cms-link,
    .block-banners {
        margin: 15px 0 0;
        text-align: center;
    }
}

.minicart-wrapper {
    @include lib-dropdown(
        $_toggle-selector                     : '.action.showcart',
        $_options-selector                    : '.block-minicart',
        $_dropdown-toggle-icon-content        : $icon-cart,
        $_dropdown-toggle-active-icon-content : $icon-cart,
        $_dropdown-list-item-padding          : false,
        $_dropdown-list-item-hover            : false,
        $_icon-font-position                  : before,
        $_icon-font-size                      : 35px,
        $_icon-font-line-height               : 33px,
        $_icon-font-color                     : $minicart-icons-color,
        $_icon-font-color-hover               : $minicart-icons-color-hover,
        $_icon-font-color-active              : $minicart-icons-color
    );
    position: absolute;
    right: 40px;
    top: 50%;
    margin-top: -9px;

    .block-minicart {
        padding: 30px 30px 0;
        box-shadow: 0px 4px 20px 4px rgba(0, 0, 0, 0.15);
        margin-top: 10px;
        width: 315px;
        z-index: 101;
        right: -23px;
        border: 1px solid $color-gray-light7;

        .block-title {
            display: none;
        }

        &:after {
            left: auto;
            right: 25px;
        }

        &:before {
            left: auto;
            right: 26px;
        }
    }

    .product {
        .actions {
            margin-top: -38px;
            padding-left: 75px;

            > .primary,
            > .secondary {
                display: inline;
            }

            > .primary {
                float: left;
            }
        }
    }

    .action {
        &.close {
            @include lib-button-icon(
                $icon-remove,
                $_icon-font-size        : 32px,
                $_icon-font-line-height : 32px,
                $_icon-font-text-hide   : true
            );
            @include lib-button-reset();
            height: 40px;
            position: absolute;
            right: 0;
            top: 0;
            width: 40px;
            display: none;
        }

        &.showcart {
            &.active:before,
            &:before {
                content: "";
            }

            white-space: nowrap;

            svg.cart {
                fill: $color-black;
            }
            &.active svg.cart,
            &:hover svg.cart {
                fill: rgba($color-black, 0.5);
            }

            .text {
                @extend .abs-visually-hidden;
            }

            .counter.qty {
                @include lib-css(background, $active__color);
                @include lib-css(color, $page__background-color);
                @include lib-css(line-height, $minicart-qty__height);
                height: 14px;
                margin: 0;
                min-width: 14px;
                overflow: hidden;
                text-align: center;
                white-space: normal;
                font-size: 9px;
                position: absolute;
                border-radius: 7px;
                left: 28px;
                top: 4px;
                padding: 0 5px;
                letter-spacing: 1px;
                box-sizing: border-box;

                &.empty {
                    display: none;
                }

                .loader {
                    > img {
                        @include lib-css(max-width, $minicart-qty__height);
                    }
                }
            }

            .counter-label {
                @extend .abs-visually-hidden;
            }
        }
    }

    .minicart-widgets {
        margin-top: 10px;
    }
}

.cms-home .minicart-wrapper .action.showcart:not(:hover):not(.active) svg.cart {
    fill: $color-white;
}

.minicart-items-wrapper {
    border-left: 0;
    border-right: 0;
    overflow-x: auto;
    margin: 0 -10px;
    padding: 0 10px 0;
}

.minicart-items {
    @include lib-list-reset-styles();
    min-height: 200px;

    .product-item {
        padding: 20px 0;

        &:not(:first-child) {
            @include lib-css(border-top, 1px solid $minicart__border-color);
        }

        &:first-child {
            padding-top: 0;
        }

        > .product {
            @extend .abs-add-clearfix;
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
        border: 2px solid $color-gray-light7;
        padding: 3px;
        font-size: 0
    }

    .product-item-pricing {
        .label {
            display: inline-block;
            width: 4.5rem;
        }
    }

    .price-minicart {
        margin-bottom: $indent__xs;
    }

    .product {
        > .product-item-photo,
        > .product-image-container {
            float: left;
            margin-right: 15px;
        }

        .toggle {
            @include lib-icon-font(
                $_icon-font-content     : $icon-down,
                $_icon-font-size        : 28px,
                $_icon-font-line-height : 16px,
                $_icon-font-text-hide   : false,
                $_icon-font-position    : after,
                $_icon-font-display     : block
            );
            cursor: pointer;
            position: relative;

            &:after {
                position: static;
                right: $indent__base;
                top: 0;
            }
        }

        &.active {
            > .toggle {
                @include lib-icon-font-symbol(
                    $_icon-font-content  : $icon-up,
                    $_icon-font-position : after
                );
            }
        }
    }

    .product-item-name {
        font-weight: $font-weight__bold;
        margin: 0 0 5px;
        font-size: 14px;
        line-height: 22px;
    }

    .product-item-details {
        .price {
            line-height: 22px;
            font-weight: normal;
        }

        .price-including-tax,
        .price-excluding-tax {
            margin: $indent__xs 0 0;
        }

        .weee[data-label] {
            @include lib-font-size(11);
            .label {
                @extend .abs-no-display;
            }
        }

        .details-qty {
            clear: left;
            margin-top: 20px;

            .label {
                display: none;
            }
        }

        .product.options {
            display: none;
        }
    }

    .product.options {
        .tooltip.toggle {
            @include lib-icon-font(
                $icon-down,
                $_icon-font-size        : 28px,
                $_icon-font-line-height : 28px,
                $_icon-font-text-hide   : true,
                $_icon-font-margin      : -3px 0 0 7px,
                $_icon-font-position    : after
            );

            .details {
                display: none;
            }
        }
    }

    .details-qty,
    .price-minicart {
        .label {
            @extend .abs-colon;
        }
    }

    .item-qty {
        margin-right: 10px;
        text-align: center;
        width: 60px;
        font-weight: 500;
        font-size: 14px;
    }

    .action.edit,
    .update-cart-item {
        border: 1px solid $color-brownie-light1;
        display: inline-block;
        font-size: 14px;
        line-height: 20px;
        padding: 10px 25px;
        background: #fff;
        color: $color-brownie1;
        font-weight: 400;
        margin-top: 0;
        width: auto;

        &:hover {
            border-color: $color-brownie1;
            color: $color-brownie1;
            text-decoration: none;
        }
    }

    .subtitle {
        display: none;
    }

    .action {
        &.delete {
            background-image: url('../media/bin.svg');
            vertical-align: -2px;
            background-position: center;
            background-repeat: no-repeat;
            float: right;
            width: 16px;
            height: 16px;
            font-size: 0;
            padding: 11px;
            margin-top: 0;
            border: none;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__xs) {
    .minicart-wrapper .block-minicart {
        width: 290px;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .minicart-wrapper {
        margin-left: 13px;
    }
}


.checkout-index-index {
    .block-minicart {
        @include max-screen($screen__m) {
            display: none;
        }

        .block-content > .actions .primary {
            display: none;
        }
    }
}

.page-header .minicart-wrapper {
    @include max-screen($screen__m) {
        margin-top: -16px;
        right: 22px;

        .block-minicart {
            right: -10px;

            &:before {
                right: 14px;
            }

            &:after {
                right: 13px;
            }
        }
    }
}
