/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.personalize-container .full-screen-container.editor-container {
    z-index: 22;

    .config-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin-bottom: 0;

        .personalize-editor {
            position: absolute !important;
            top: 42px;
            right: 0;
            bottom: 50px;
            left: 0;

            @include min-screen($screen__l) {
                top: 50px;
            }

            ul[role="menubar"] {
                 > div:first-child {
                     margin-top: 10px;

                     > [role="menuitem"] {
                        display: none;
                     }
                }
            }

            input[type="text"],
            input[type="password"],
            input[type="url"],
            input[type="tel"],
            input[type="search"],
            input[type="number"],
            input[type="datetime"],
            input[type="email"] {
                padding: 5px;
                background-color: #737373;
                height: 30px;
                border: 1px solid #161616;
            }
        }
    }
}
