/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

@import 'module/personalize';
@import 'module/top-links';
@import 'module/navigation';
@import 'module/board';
@import 'module/upload';
@import 'module/preview';
@import 'module/options';
@import 'module/editor';
@import 'module/collager';
@import 'module/footer';
