/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.product.data.items {
    @include lib-data-accordion();
    margin-bottom: $indent__base;
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product.data.items {
        @include lib-data-tabs(
            $_tab-content-border-top-status: true
        );
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .product.data.items {
        @include lib-data-accordion();
        .data.item {
            display: block;
        }
    }
}
