/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.personalize-upload-container {
    .upload-files {
        text-align: center;
        margin-bottom: 20px;
    }

    .upload-label {
        color: $color-brownie1;
        border: 1px solid $color-brownie1;
        background-color: unset;
        display: inline-block;
        font-weight: 300;
        cursor: pointer;

        &:after {
            content: "";
            display: inline-block;
            background-image: url('../media/upload.svg');
            background-repeat: no-repeat;
            background-position: center;
            vertical-align: -5px;
            width: 20px;
            height: 20px;
            margin-left: 5px;
            margin-right: -5px;
        }
    }
}


