/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.candle-in-categories-widget {
    .title {
        max-width: 100%;
        font-size: 48px;
        line-height: 56px;
        text-align: center;
        margin-bottom: 80px;
    }

    .cat-container {
        max-width: 300px;
        margin: 0 auto;
    }

    .categories {
        font-size: 0;

        .category {
            display: inline-block;
            vertical-align: top;
            text-align: center;

            a {
                display: inline-block;
                position: relative;
                @include hover-images();
            }

            .cat-title {
                font-family: $font-family-name__headings;
                font-size: 28px;
                font-weight: 700;
                font-style: normal;
                line-height: 40px;
                text-align: center;
            }

            .cat-description {
                font-size: 16px;
                font-weight: 400;
                font-style: normal;
                letter-spacing: normal;
                line-height: 26px;
                text-align: center;
            }
        }

        &.total-1 .category {
            width: 100%;
        }

        &.total-2 .category {
            width: 50%;
        }

        &.total-3 .category {
            width: 33.33%;
        }

        &.total-4 .category {
            width: 25%;
        }

        &.total-5 .category {
            width: 20%;
        }

        &.total-6 .category {
            width: 16.67%;
        }
    }
}
