/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/light/opensans-300',
    $font-weight: 300,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/regular/opensans-400',
    $font-weight: 400,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/semibold/opensans-600',
    $font-weight: 600,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/bold/opensans-700',
    $font-weight: 700,
    $font-style: normal
);

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    h1 {
        @include lib-css(font-size, $h1__font-size-desktop);
        @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
    }
}

.items {
    @include lib-list-reset-styles();
}

h2 {
    font-family: $font-family-name__headings;
    font-size: 56px;
    font-weight: 400;
    line-height: 62px;
}

h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
}

.column.main {
    > p,
    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6 {
        @include column-design-padding();
    }
}

body {
    ::selection {
        background: $color-brownie1;
    }
}
