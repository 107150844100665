/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//
//  Variables
//  _____________________________________________

$checkout-progress-bar__font-size                              : 22px !default;
$checkout-progress-bar__font-weight                            : 600 !default;
$checkout-progress-bar__margin                                 : 30px !default;

$checkout-progress-bar-item__background-color                  : $color-brownie-light1 !default;
$checkout-progress-bar-item__border-radius                     : 6px !default;
$checkout-progress-bar-item__color                             : $primary__color !default;
$checkout-progress-bar-item__margin                            : $indent__s !default;
$checkout-progress-bar-item__width                             : auto !default;
$checkout-progress-bar-item__active__background-color          : $color-brownie2 !default;
$checkout-progress-bar-item__complete__color                   : $color-brownie1 !default;
$checkout-progress-bar-item-border__color                      : $color-brownie1 !default;

$checkout-progress-bar-item-element__width                     : 38px !default;
$checkout-progress-bar-item-element__height                    : $checkout-progress-bar-item-element__width !default;

$checkout-progress-bar-item-element-inner__background-color    : $page__background-color !default;
$checkout-progress-bar-item-element-inner__color               : $checkout-progress-bar-item__color !default;
$checkout-progress-bar-item-element-outer-radius__width        : 6px !default;
$checkout-progress-bar-item-element-inner__width               : $checkout-progress-bar-item-element__width - ($checkout-progress-bar-item-element-outer-radius__width * 2) !default;
$checkout-progress-bar-item-element-inner__height              : $checkout-progress-bar-item-element-inner__width !default;
$checkout-progress-bar-item-element-inner__active__content     : $icon-checkmark !default;
$checkout-progress-bar-item-element-inner__active__font-size   : 22px !default;
$checkout-progress-bar-item-element-inner__active__line-height : 1 !default;

//
//  Checkout Progress Bar
//  ---------------------------------------------

.opc-progress-bar {
    @extend .abs-reset-list;
    @include lib-css(margin, 0 0 $checkout-progress-bar__margin);
    counter-reset: i;
    font-size: 0;

    @include max-screen($screen__m) {
        margin-bottom: 30px;
    }
}

.opc-progress-bar-item {
    @include lib-css(margin, 0 0 $checkout-progress-bar-item__margin);
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: top;
    width: 50%;

    > span {
        display: block;
        @include min-screen($screen__m) {
            display: inline-block;
        }

        @include max-screen($screen__m) {
            font-size: 18px;
        }

        word-wrap: break-word;

        @include lib-typography(
            $_color       : $checkout-progress-bar-item__background-color,
            $_font-family : false,
            $_font-size   : $checkout-progress-bar__font-size,
            $_font-style  : false,
            $_font-weight : $checkout-progress-bar__font-weight,
            $_line-height : 28px
        );

        font-size: 1.8rem;
        padding-bottom: 10px;
        padding-left: 15px;
        padding-right: 15px;

    }

    &._active {
        > span {
            @include lib-css(color, $checkout-progress-bar-item__color);
            border-bottom: 4px solid $checkout-progress-bar-item-border__color;


            @include max-screen($screen__m) {
                border-width: 2px;
            }
        }
    }

    &._complete {
        cursor: pointer;

        > span {
            @include lib-css(color, $color-brownie1);
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-progress-bar .opc-progress-bar-item {
        @include lib-css(width, $checkout-progress-bar-item__width);
        margin-right: 30px;
    }
}
