/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//
//  Breadcrumbs variables
//  _____________________________________________

$breadcrumbs__font-size                          : $font-size__xs !default;
$breadcrumbs__display                            : false !default;
$breadcrumbs__container-margin                   : 0 0 60px !default;
$breadcrumbs__padding                            : false !default;

$breadcrumbs-icon__use                           : true !default;
$breadcrumbs-icon__font-content                  : $icon-next !default;
$breadcrumbs-icon__font                          : $icon-font !default;
$breadcrumbs-icon__font-size                     : 24px !default;
$breadcrumbs-icon__font-line-height              : 18px !default;
$breadcrumbs-icon__font-color                    : false !default;
$breadcrumbs-icon__font-margin                   : 0 !default;
$breadcrumbs-icon__font-vertical-align           : top !default;

//  Current page
$breadcrumbs-current__color                      : $color-gray64 !default;
$breadcrumbs-current__font-weight                : $font-weight__regular !default;
$breadcrumbs-current__background                 : false !default;
$breadcrumbs-current__border                     : false !default;

//  Current page - gradient background
$breadcrumbs-current__gradient                   : false !default;
$breadcrumbs-current__gradient-direction         : vertical !default;
$breadcrumbs-current__gradient-color-start       : false !default;
$breadcrumbs-current__gradient-color-end         : false !default;

$breadcrumbs-separator__symbol                   : false !default;
$breadcrumbs-separator__color                    : $breadcrumbs-current__color !default;

//
//  Link
//  ---------------------------------------------

//  Gradient
$breadcrumbs-link__gradient                      : false !default; // [true|false]
$breadcrumbs-link__gradient-direction            : false !default; // [true|false]

$breadcrumbs-link__color                         : $primary__color !default;
$breadcrumbs-link__background                    : false !default;
$breadcrumbs-link__border                        : false !default;
$breadcrumbs-link__text-decoration               : none !default;
$breadcrumbs-link__gradient-color-start          : false !default;
$breadcrumbs-link__gradient-color-end            : false !default;

//  Visited
$breadcrumbs-link__visited__color                : $primary__color !default;
$breadcrumbs-link__visited__background           : $breadcrumbs-link__background !default;
$breadcrumbs-link__visited__border               : $breadcrumbs-link__border !default;
$breadcrumbs-link__visited__text-decoration      : none !default;
$breadcrumbs-link__visited__gradient-color-start : false !default;
$breadcrumbs-link__visited__gradient-color-end   : false !default;

//  Hover
$breadcrumbs-link__hover__color                  : $primary__color !default;
$breadcrumbs-link__hover__background             : false !default;
$breadcrumbs-link__hover__border                 : $breadcrumbs-link__border !default;
$breadcrumbs-link__hover__text-decoration        : underline !default;
$breadcrumbs-link__hover__gradient-color-start   : false !default;
$breadcrumbs-link__hover__gradient-color-end     : false !default;

//  Active
$breadcrumbs-link__active__color                 : $primary__color !default;
$breadcrumbs-link__active__background            : $breadcrumbs-link__background !default;
$breadcrumbs-link__active__border                : $breadcrumbs-link__border !default;
$breadcrumbs-link__active__text-decoration       : none !default;
$breadcrumbs-link__active__gradient-color-start  : false !default;
$breadcrumbs-link__active__gradient-color-end    : false !default;
