/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

body.cms-home {
    background-color: $color-black;

    .logo .logo-text {
        fill: $color-white;
    }

    .loading-mask {
        background: rgba(0, 0, 0, 0.5);

        .loader > img {
            display: none;
        }
    }

    .header {
        .link.my-account-link {
            a:not(:hover) svg.dummy {
                fill: $color-white;
            }

            a:hover svg.dummy {
                fill: rgba($color-white, 0.5);
            }
        }

        .link.wishlist {
            a:not(:hover) svg.heart {
                fill: $color-white;
            }
            a:hover svg.heart {
                fill: rgba($color-white, 0.5);
            }
        }

        &.panel > .header.links > .link.create-a-candle-in-link a {
            color: $color-white;
            outline: 1px solid $color-white;
            border: 1px solid transparent;

            &:hover {
                border-color: $color-brownie1;
                background-color: $color-white;
                color: $color-brownie1;
                //color: rgba($color-white, 0.5);
            }
        }
    }

    .minicart-wrapper .action.showcart.active svg.cart,
    .minicart-wrapper .action.showcart:hover svg.cart {
        fill: rgba($color-white, 0.5);
    }

    .nav-toggle {
        &:hover {
            svg.menu-stripes {
                fill: rgba($color-white, 0.5);
            }

            .menu {
                color: rgba($color-white, 0.5);
            }
        }
    }
}
