/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.option-container {
    font-size: 0;

    input[type="radio"] {
        display: none;
    }

    .label {
        position: relative;
        display: inline-block;
        color: $color-gray-light8;
        border: 1px solid $color-gray-light7;
        padding: 5px 15px;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        text-align: center;
        cursor: pointer;
        min-width: 60px;
        box-sizing: border-box;
        width: 100%;
        border-radius: 5px;

        &:hover {
            border-color: rgba($color-brownie1, .2);
        }

        .circle-thumbnail {
            position: absolute;
            top: 10px;
            left: 10px;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 1px solid $color-gray-light7;
            background-color: $color-white;

            .interior {
                border-radius: 50%;
                position: absolute;
                top: 3px;
                right: 3px;
                bottom: 3px;
                left: 3px;
            }
        }
    }

    .inline-options {
        margin: 0 0 15px;

        .options {
            margin-bottom: 5px;

            .option {
                display: inline-block;
                padding: 0 5px 10px;
                max-width: $screen__xxs;
                width: 100%;
                box-sizing: border-box;

                @include min-screen($screen__xxs) {
                    width: 50%;
                }

                .label {
                    display: inline-block;
                }
            }
        }

        .descriptions {
            color: #aeaeae;
            font-weight: 400;
            line-height: 16px;

            .option-description {
                font-size: 12px;
            }
        }
    }

    .list-options {

        ul {
            padding: 0;

            li {
                list-style: none;
                margin-bottom: 10px;

                .label {
                    text-align: left;
                    padding: 15px;
                    border-radius: 5px;

                    .option-title {
                        color: $color-black;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px;
                    }

                    .info {
                        margin-top: 5px;

                        .option-description {
                            font-weight: 400;
                        }

                        .option-price {
                            float: right;

                            &.additional-price {
                                &:before {
                                    content: "+";
                                    display: inline;
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }

                &.has-thumbnails {
                    .label {
                        padding: 20px 15px 20px 55px;

                        .option-title {
                            font-size: 14px;
                            color: $color-gray-light8;
                        }

                        .info {
                            margin-top: 0;

                            .option-price {
                                position: absolute;
                                top: 17px;
                                right: 15px;
                            }
                        }
                    }
                }

                &.disabled {
                    pointer-events: none;

                    .label,
                    .label .option-title,
                    input[type="radio"]:checked+.label,
                    input[type="radio"]:checked+.label .option-title {
                        color: $color-gray-light3;
                        background-color: $color-gray-light0;
                    }

                    input[type="radio"]:checked+.label {
                        background-image: linear-gradient(135deg, rgba($color-gray-darken4, .2) 7.14%, $color-white 7.14%, $color-white 50%, rgba($color-gray-darken4, .2)  50%, rgba($color-gray-darken4, .2)  57.14%, $color-white 57.14%, $color-white 100%);
                    }
                }
            }
        }
    }

    input[type="radio"]:checked+.label {
        color: $color-black;
        border-color: $color-brownie1;
        background-image: linear-gradient(135deg, rgba($color-brownie1, .2) 7.14%, $color-white 7.14%, $color-white 50%, rgba($color-brownie1, .2)  50%, rgba($color-brownie1, .2)  57.14%, $color-white 57.14%, $color-white 100%);
        background-size: 5px 5px;

        .option-price {
            font-weight: 700;
        }
    }
}
