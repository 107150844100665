/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//
//  Poster appearance styles
//  _____________________________________________

.pagebuilder-slide-wrapper {
    .pagebuilder-poster-content {
        width: 100%;
    }
}
