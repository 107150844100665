/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

@mixin hover-images() {
    border: 2px solid $color-gray-light7;
    padding: 3px;
    transition: 0.2s;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        transition: 0.2s;
        box-shadow: inset 0 0 0 0 rgba(255, 255, 255, 0);
    }

    &:hover {
        border-color: $color-brownie1;
        &:after {
            box-shadow: inset 0 0 0 10px rgba(255, 255, 255, 1);
        }
    }
}

@mixin full-spread() {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

@mixin wide-gray-section() {
    padding: 50px 0;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        background-color: $color-gray-light7;
        top: 0;
        right: -50%;
        left: -50%;
        bottom: 0;

        @include max-screen($screen__m) {
            right: -15px;
            left: -15px;
        }
    }
}

@mixin wide-gray-section-title() {
    font-family: $font-family-name__headings;
    font-size: 28px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    margin: 40px 0 60px;
}
