/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.block-cms-hierarchy-link {
    &.widget {
        display: block;
        margin-bottom: $indent__base;
    }
}

.block-cms-hierarchy-link-inline {
    &.widget {
        margin: 0;
    }
}
