/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//
//  Default appearance styles
//  _____________________________________________

[data-content-type$='block'] {
    .block {
        p:last-child {
            margin-bottom: 1rem;
            margin-top: 0;
        }
    }
}
