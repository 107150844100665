/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.personalize-footer {
    position: fixed;
    display: block;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-brownie1;
    color: $color-white;
    font-weight: 500;
    font-size: 0;
    height: 50px;
    transition: background-color 0.15s;
    z-index: 20;

    @include min-screen($screen__l) {
        display: none;
    }

    &.action {
        display: block;
        background-color: #252525;
        text-align: right;
        padding: 0;
        border-top: none;
        height: auto;
        min-height: 40px;
        z-index: 25;

        &:after {
            display: none;
        }

        .action {
            margin: 0 auto;

            @include min-screen($screen__l) {
                padding-left: 287px;
            }

            button {
                margin: 0 auto;
                cursor: pointer;
                font-size: 12px;
                letter-spacing: 1.5px;
                text-transform: uppercase;
                color: $color-white;
                background-color: $color-brownie1;
                display: inline-block;
                padding: 15px 15px;
                width: 100%;
                border-radius: 0;

                &:hover {
                    background-color: rgb(22,22,22);
                }
            }
        }
    }

    .qty {
        display: inline-block;
        position: relative;
        border-right: 1px solid rgba($color-white, 0.4);
        padding: 0 15px;
        font-size: 14px;
        line-height: 22px;

        label {
            display: inline-block;
        }

        input {
            display: inline-block;
            text-align: center;
            width: 40px;
            height: 30px;
            padding: 0;
            margin: 0 5px;
            color: $color-white;
            font-weight: 300;
            font-size: 14px;
            border: 1px solid $color-white;
            background-color: $color-brownie1;
        }
    }

    .price {
        display: inline-block;
        padding: 15px 15px;
        font-size: 14px;
        line-height: 20px;
    }

    .actions {
        position: absolute;
        right: 0;
        top: 0;
    }

    button {
        font-size: 14px;
        background-color: unset;
        background-repeat: no-repeat;
        border: none;
        line-height: 20px;
        padding: 15px;
        font-weight: 500;
        background-position: right 15px center;

        &.call {
            font-weight: 400;
            display: block;
            width: 100%;
            text-align: center;

            &:after {
                content: "";
                transform: rotate(180deg);
                background-image: url('../media/arrow-left.svg');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                display: inline-block;
                width: 32px;
                margin-right: -32px;
                vertical-align: inherit;
                height: 18px;
            }
        }

        &.action {
            display: inline-block;
            padding: 15px;
            line-height: 20px;
            cursor: pointer;

            &:after {
                content: "";
                display: inline-block;
                width: 35px;
                height: 20px;
                background-position: center;
                background-repeat: no-repeat;
                vertical-align: -5px;
            }
        }

        &.cart {
            color: $color-white;

            &:after {
                background-image: url('../media/cart-plus.svg');
            }
        }

        &.save {
            color: $color-white;
            &:after {
                background-image: url('../media/upload-white.svg');
            }
        }

        &.download {
            color: $color-white;
            &:after {
                background-image: url('../media/upload-white.svg');
                transform: rotate(180deg);
            }
        }

        &.confirm {
            &:after {
                display: none;
            }
        }

        @include max-screen($screen__xs) {
            font-size: 0;
        }
    }
}
