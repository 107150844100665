/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//
//  Collage center appearance styles
//  _____________________________________________

[data-appearance='collage-centered'] {
    .pagebuilder-banner-wrapper {
        .pagebuilder-overlay {
            margin-left: auto;
            margin-right: auto;
        }
    }
}
