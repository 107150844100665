/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.candle-in-container {
    position: relative;
    padding-bottom: 100%;

    .preview {
        position: absolute;

        canvas {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    .top-controls {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        padding: 15px;
        color: $color-white;
        text-align: center;
        user-select: none;

        .candle-control {
            margin: 0 25px;
            display: inline-block;
        }

        label {
            font-size: 12px;
            vertical-align: top;
            display: inline-block;
            margin: 0 5px;
        }

        button {
            font-size: 16px;
            line-height: 1;
            width: 20px;
            height: 20px;
            color: $color-white;
            background-color: transparent;
            border: none;
            padding: 0;
            text-align: center;
            vertical-align: top;
            display: inline-block;

            &:hover {
                background-color: mix(white, $color-brownie1, 10%);
            }
        }
    }

    .side-bar {
        margin: 0 15px;
        height: 5px;
        background-color: $color-white;
        position: absolute;
        bottom: 5%;
        left: 30px;
        right: 30px;

        @include max-screen($screen__l) {
            pointer-events: none;
        }

        .current-side {
            cursor: move;
            width: 25%;
            position: absolute;
            height: 100%;
            background-color: $color-brownie1;
            left: 0;

            &:active {
                cursor: grabbing;
            }
        }
    }

    @include min-screen($screen__l) {
        padding-bottom: 0;
        @include full-spread();

        .preview {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
}

.personalize-container.preview {
    position: fixed;
    top: 80px;
    bottom: 0;

    @include max-screen($screen__m) {
        top: 65px;
    }
}


.personalize-configurator.preview {
    .configurator-input,
    .side-bar {
        display: none;
    }

    .personalize-footer {
        display: block;
        bottom: 0;

        @include max-screen($screen__l) {
            bottom: 70px;
            left: 25px;
            right: 25px;
            border: 1px solid $color-brownie1;
            background-color: rgba($color-brownie1, 0.5);
        }
    }

    @include max-screen($screen__l) {
        @include full-spread();
        .personalize-navigation-container {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    .candle-in-container {
        @include max-screen($screen__l) {
            padding-bottom: 0;
            @include full-spread();

            .preview {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }
    }
}
