/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.block-wishlist-search {
    .form-wishlist-search {
        margin: $form-field__vertical-indent 0 0;
    }
}
