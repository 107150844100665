/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//
//  Product Lists
//  _____________________________________________

.product {
    &-items {
        @extend .abs-reset-list;
        text-align: center;
    }

    &-item {
        @extend .abs-add-box-sizing;
        vertical-align: top;

        .products-grid & {
            display: inline-block;
            width: 50%;
        }

        &-name {
            @extend .abs-product-link;
            display: block;
            margin: 0 0 5px;
            word-wrap: break-word;
            hyphens: auto;
            font-style: normal;
            font-size: 18px;
            line-height: 26px;
            font-weight: 700;

            a.product-item-link {
                font-weight: 700;

                &:active {
                    text-decoration: none;
                }
            }
        }

        &-short-description {
            font-size: 14px;
            line-height: 22px;
        }

        &-info {
            max-width: 100%;
            margin: 0 auto;
        }

        &-photo {
            font-size: 0;
            display: block;
            margin-bottom: 20px;

            .product-image-container {
                position: relative;
                @include hover-images();
            }
        }

        &-actions {
            display: none;
            font-size: 0;

            .actions-secondary {
                & > .action {
                    @extend .abs-actions-addto-gridlist;
                    &:before {
                        margin: 0;
                    }

                    span {
                        @extend .abs-visually-hidden;
                    }
                }
            }
        }

        &-description {
            margin: 0 0 15px;
            font-size: 14px;
            line-height: 22px;
            font-weight: 300;
        }

        .item-add-to-cart {
            font-size: 11px;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: $color-brownie1;
        }

        .choice {
            input,
            label {
                display: inline-block;
                vertical-align: text-top;
            }

            input[type="checkbox"] {
                height: 25px;
            }

            label {
                padding-left: 5px;
            }
        }

        .product-reviews-summary {
            .rating-summary {
                margin: 0 4px 0 0;
            }

            .reviews-actions {
                font-size: $font-size__s;
                margin-top: 5px;
                text-transform: lowercase;
            }
        }

        .price-box {
            margin: $indent__s 0 15px;

            .price {
                font-size: 14px;
                font-weight: $font-weight__bold;
                white-space: nowrap;
            }

            .price-label {
                font-size: $font-size__s;
                line-height: 22px;
                font-weight: 300;

                &:after {
                    content: ':';
                }
            }
        }

        .special-price,
        .minimal-price {
            .price {
                font-size: 14px;
                font-weight: $font-weight__bold;
            }

            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            display: block;
        }

        .old-price {
            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            margin-top: 5px;

            .price-label {
                color: $link__color;
                font-size: 14px;
            }

            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .action.tocompare {
            @include lib-icon-font-symbol($icon-compare-empty);
        }

        .tocart {
            white-space: nowrap;
            color: $color-brownie1;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 11px;
            line-height: 16px;
            letter-spacing: 1px;
            border: none;
            background-color: transparent;
            padding: 0;

            &:hover {
                background-color: transparent;
                border: none;
                color: $color-brownie1;
                text-decoration: none;
            }
        }
    }
}

.column.main {
    .product {
        &-items {
            text-align: left;
            font-size: 0;

            @include min-screen($screen__m) {
                margin-left: -30px;
            }
        }

        &-item {
            @include min-screen($screen__m) {
                padding-left: 30px;
            }

            .product-item-info {
                text-align: left;
            }
        }
    }
}

.price-container {
    .price {
        font-size: 14px;
    }

    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax + .price-excluding-tax,
    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        font-size: 11px;
    }

    .weee {
        &:before {
            content: '('attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .product {
        &-item {
            display: table;
            width: 100%;

            &-info {
                display: table-row;
            }

            &-photo {
                display: table-cell;
                padding: 0 $indent__l $indent__l 0;
                vertical-align: top;
                width: 1%;
            }

            &-details {
                display: table-cell;
                vertical-align: top;
            }
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .products-list .product {
        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }
}

@include min-screen($screen__s) {
    .product {
        &-item {
            .products-grid & {
                margin-bottom: $indent__l;
            }

            &-actions {
                display: block;

                .products-grid & {
                    margin: $indent__s 0 0;
                }

                .actions-primary + .actions-secondary {
                    display: table-cell;
                    padding-left: 5px;
                    white-space: nowrap;
                    width: 50%;
                    & > * {
                        white-space: normal;
                    }
                }

                .actions-primary {
                    display: table-cell;
                }
            }
        }
    }

    .products-grid .product-item {
        width: 33.3333%;
    }

    .page-products,
    .page-layout-1column,
    .page-layout-3columns,
    .page-products.page-layout-1column,
    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 33.3333%;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__l) {
    .page-products {
        .products-grid {
            .product-item {
                width: 33.3333%;
            }
        }
    }

    .page-products.page-layout-1column {
        .products-grid {
            .product-item {
                width: 25%;
                margin-bottom: 60px;
            }
        }
    }

    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 50%;
            }
        }
    }
}
@include min-screen($screen__l) {
    .products-grid {
        .product-item {
            width: 20%;
        }
    }

    .page-layout-1column {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-layout-3columns {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products {
        .products-grid {
            .product-items {
                margin: 0;
            }

            .product-item {
                margin-left: calc((100% - 4 * 23.233%) / 3);
                padding: 0;
                width: 23.233%;

                &:nth-child(4n + 1) {
                    margin-left: 0;
                }

                .product-item-description {
                    display: none;
                }
            }
        }
    }

    .page-products {
        &.page-layout-1column {
            .products-grid {
                .product-item {
                    margin-left: 0;
                    width: 240px;
                    margin-right: 20px;
                }
            }
        }

        &.page-layout-3columns {
            .products-grid {
                .product-item {
                    margin-left: 1%;
                    width: 32.667%;

                    &:nth-child(3n) {
                        margin-left: 1%;
                    }

                    &:nth-child(3n + 1) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

// columns

.catalog-category-view.page-layout-2columns-left {
    .column.main {
        width: 100%;

        > p,
        > h1,
        > h2,
        > h3,
        > h4,
        > h5,
        > h6 {
            margin-left: 0;
        }

        .products-grid {
            margin-bottom: 40px;
        }
    }
}
