/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//
//  Default appearance styles
//  _____________________________________________

[data-content-type='heading'] {
    word-wrap: break-word;
}
