/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.candle-in-text-image-block-widget {
    font-size: 0;
    margin: 50px 0;

    .title1 {
        font-size: 18px;
        font-weight: 700;
        font-style: normal;
        line-height: 26px;
        margin-bottom: 30px;
    }

    .title2 {
        font-family: $font-family-name__headings;
        font-size: 48px;
        font-weight: 400;
        line-height: 56px;
        margin-bottom: 30px;
    }

    .text {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
    }

    .text-block,
    .img-block {
        box-sizing: border-box;
        vertical-align: middle;

        @include min-screen($screen__l) {
            display: inline-block;
            width: 50%;
        }
    }

    .text-block {
        padding: 15px;

        @include min-screen($screen__l) {
            padding: 100px;
        }
    }

    .img-block {
        text-align: center;

        @include min-screen($screen__l) {
            text-align: left;
            padding: 0 100px;
        }
    }
}
