/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.fotorama__shadow {
    .fotorama__wrap--fade .fotorama__stage & {
        display: none;
    }
}
