/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

// crop options
.sc-iAyFgw.kiXXlj,
.sc-htoDjs.kwDghB
{
    display: none;
}

.cMwIcs {
    overflow: auto;
}

@include max-screen($screen__l) {

    // container
    .sc-bdVaJa.cFHmcX {
        overflow: auto;
        height: auto;
        display: grid;
        flex-direction: unset;
        grid-template-columns: 0fr 1fr;
    }

    // editor
    .sc-dqBHgY.eWJRaB {
        position: relative;
        height: auto;
        display: block;
        grid-column: 1 / 3;
        grid-row: 1;
        min-height: 100vw;
        width: 100%;
    }

    // toolcontrolbar
    .sc-hmXxxW.cDnswM {
        display: block;
        flex: none;
        margin-bottom: 50px;
        padding-bottom: 50px;
        grid-column: 2;
        grid-row: 2;
        width: auto;
        flex-direction: unset;
        position: relative;
    }

    // toolbar
    .sc-dEoRIm.fpfpUX {
        display: block;
        flex: none;
        margin-bottom: 50px;
        padding-bottom: 50px;
        flex-direction: unset;
        height: auto;
        grid-column: 1;
        grid-row: 2;
    }

    //card containers
    .jFwHXR,
    .NuaUa {
        margin-right: 8px;
    }

    .sc-ipXKqB.cRFVpR {
        width: auto;
        > div {
            display: inline-block;
            margin-right: 8px;
        }
    }
}

@include min-screen ($screen__l) {
    .sc-dqBHgY.eWJRaB {
        height: auto !important;
    }
}


// crop buttons not selectable
button[aria-label="Reset to default"],
button[aria-label="Crop Format"]
{
    display: none;
}

button[aria-label="Square"],
button[aria-label="16:9"],
button[aria-label="9:16"] {
    pointer-events: none;
    background-color: rgb(22, 22, 22);
    filter: grayscale(100%) brightness(40%) sepia(100%) contrast(0.8);
}


button.sc-chPdSV * {
    pointer-events: none;
}
