/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//
//  Checkout Estimated Total
//  ---------------------------------------------

.opc-estimated-wrapper {
    @extend .abs-add-clearfix;
    @extend .abs-no-display-desktop;
    margin: 0 0 30px;
    padding: 15px 0 0;

    @include min-screen($screen__m) {
        display: none;
    }

    .estimated-block {
        float: left;
        font-size: 14px;

        .estimated-label {
            display: block;
            margin: 0 0 $indent__xs;
            @include lib-css(font-weight, $font-weight__bold);
        }

        .estimated-price {
            display: block;
        }
    }

    .minicart-wrapper {
        top: auto;
        margin-top: auto;

        button {
            border: 0;
        }

        .action {
            &.showcart {
                padding: 0 20px 0 0;

                &:before {
                    content: "";
                    display: inline-block;
                    width: 35px;
                    height: 20px;
                    background-position: center;
                    background-repeat: no-repeat;
                    vertical-align: -5px;
                    background-image: url('../media/cart.svg');
                    margin-right: -8px;
                }

                .counter.qty {
                    left: auto;
                    right: 0;
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________
