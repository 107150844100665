/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.footer-container {
    color: $color-gray69;
    text-align: center;
    background-color: $color-black;
    padding: 50px 20px 25px;

    .row {
        max-width: $layout__max-width;
        margin: 0 auto;
    }

    .columns {
        border-top: 1px solid $color-gray19;
        padding: 30px 0;
        font-size: 0;

        .column {
            width: 100%;
            text-align: center;
            display: inline-block;
            vertical-align: top;
            font-size: 14px;
            line-height: 22px;

            @include min-screen($screen__l) {
                width: 50%;
                text-align: left;
            }

            ul {
                padding-left: 0;

                li {
                    list-style: none;
                }
            }

            a {
                color: $color-gray69;
            }
        }
    }

    .row-title {
        margin-top: 0;
        color: $color-white;
        font-weight: 700;
        line-height: 26px;
        font-size: 18px;
        text-transform: none;
    }

    .column-title {
        margin-top: 0;
        color: $color-white;
        font-size: 14px;
        font-weight: 700;
    }

    .store-info {
        li {
            list-style: none;
        }

        .store-info-item {
            margin-left: -10px;

            &:before {
                content: "";
                display: inline-block;
                padding-left: 20px;
                width: 16px;
                height: 16px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                vertical-align: -3px;
            }

            &.address:before {
                background-image: url('../media/pin.svg');
            }

            &.email:before {
                height: 12px;
                background-image: url('../media/mail.svg');
            }

            &.phone:before {
                background-image: url('../media/phone.svg');
            }
        }
    }

    .social-networks {
        li {
            display: inline-block;
            margin: 0 17.5px;

            a {
                font-size: 0;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                width: 22px;
                height: 22px;
                display: inline-block;
            }

            &.facebook a {
                background-image: url('../media/facebook.svg');
            }

            &.pinterest a {
                background-image: url('../media/pinterest.svg');
            }

            &.youtube a {
                background-image: url('../media/youtube.svg');
            }

            &.linkedin a {
                background-image: url('../media/linkedin.svg');
            }

            &.instagram a {
                background-image: url('../media/instagram.svg');
            }
        }
    }
}

.cms-home .candle-in-slider.include-footer .footer-container {
    @include min-screen($screen__l) {
        top: 50%;
        transform: translateY(-50%);
        bottom: auto;
        padding-top: 0;
    }
}
