/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//
//  Variables
//  _____________________________________________

$checkout-summary__background-color : $color-black !default;
$checkout-summary__padding          : 20px 30px 30px !default;

$checkout-summary-title__margin     : $indent__s !default;
$checkout-summary-mark-value__color : $color-gray60 !default;

$checkout-summary-items__max-height : 370px !default;
$checkout-summary-items__padding    : 15px !default;

//
//  Order Summary
//  ---------------------------------------------

.column.main .opc-block-summary {
    @extend .abs-add-box-sizing;
    @include lib-css(background, $checkout-summary__background-color);
    @include lib-css(padding, $checkout-summary__padding);
    margin: 0 0 $indent__base;
    color: $color-white;

    > .title {
        @extend .abs-checkout-title;
        @include lib-css(border-bottom, 1px solid $color-gray-light8);
        color: $color-white;
        font-family: $font-family-name__headings;
        font-size: 28px;
        line-height: 40px;
        display: block;
        margin-bottom: 0;
    }

    //  Totals table
    .table-totals {
        @extend .abs-sidebar-totals;
        margin-top: 10px;
    }

    .mark {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 11px;
        line-height: 16px;
        padding: 10px 0;

        .value {
            @include lib-css(color, $checkout-summary-mark-value__color);
            display: block;
            text-transform: none;
        }
    }

    .amount {
        font-weight: bold;
        font-size: 11px;
        line-height: 16px;
    }

    .grand.incl {
        & + .grand.excl {
            .mark,
            .amount {
                border-top: 0;
                @include lib-font-size(14);
                padding-top: 0;

                strong {
                    font-weight: $font-weight__regular;
                }
            }
        }
    }

    .not-calculated {
        font-style: italic;
    }

    //
    //  Items list
    //  ---------------------------------------------

    //  Block title
    .items-in-cart {
        > .title {
            @include lib-css(padding, 20px $indent__xl 15px 0);
            cursor: pointer;
            @include lib-icon-font(
                $icon-down,
                $_icon-font-size        : 30px,
                $_icon-font-line-height : 12px,
                $_icon-font-text-hide   : true,
                $_icon-font-margin      : 3px 0 0,
                $_icon-font-position    : after,
                $_icon-font-display     : block
            );
            margin-bottom: 0;
            position: relative;

            &:after {
                position: absolute;
                top: 20px;
                content: "▾";
                right: 5px;
                font-size: 14px;
            }

            strong {
                @include lib-font-size(14);
                font-weight: $font-weight__light;
                margin: 0;
            }
        }

        &.active {
            > .title {
                @include lib-icon-font-symbol(
                    $_icon-font-content  : $icon-up,
                    $_icon-font-position : after
                );


                &:after {
                    content: "▾";
                    transform: rotate(90deg);
                }
            }
        }

        .product {
            position: relative;
        }

        .minicart-items .product-item {
            padding: 20px 0 0;
            margin-bottom: 15px;

            &:first-child {
                padding-top: 0;
            }

            &:not(:first-child) {
                border-top: 1px solid $color-gray-light8;
            }

            .product-item-details {
                .details-qty {
                    clear: none;
                    font-size: 14px;
                    margin-top: 5px;

                    .label {
                        display: inline-block;
                    }
                }

                .subtotal {
                    .price {
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
            }
        }

        border-bottom: $border-width__base solid $color-gray-light8;
    }

    //  Cart items
    .minicart-items-wrapper {
        @include lib-css(margin, 0 -($checkout-summary-items__padding) 0 0);
        @include lib-css(max-height, $checkout-summary-items__max-height);
        @include lib-css(padding, $checkout-summary-items__padding 0 0 0);
        border: 0;
    }

    .column.main & {
        .product-item {
            margin: 0;
            padding-left: 0;
        }
    }

    .product-item {
        .product-item-inner {
            margin: 0 0 $indent__s;
        }

        .product-item-name-block {
            padding-right: $indent__xs;
            text-align: left;
        }

        .subtotal {
            display: table-cell;
            text-align: right;
        }

        .price {
            @include lib-font-size(16);
            font-weight: $font-weight__regular;
        }

        .price-including-tax {
            & + .price-excluding-tax {
                margin: 0;

                .price {
                    @include lib-font-size(10);
                }
            }
        }
    }

    .actions-toolbar {
        position: relative;
        z-index: 1;

        .secondary {
            border-top: $border-width__base solid $color-gray-light8;
            display: block;
            float: none;
            margin: -1px 0 0;
            padding: 15px 0 0;
            text-align: left;

            .action {
                margin: 0;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-summary-wrapper {
        .modal-header {
            .action-close {
                display: none;
            }
        }
    }
}
