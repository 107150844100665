/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//
//  Structure
//  _____________________________________________

//
//  Z axis
//  ---------------------------------------------

$z-index-1        : 100 !default;
$z-index-2        : 200 !default;
$z-index-3        : 300 !default;
$z-index-4        : 400 !default;
$z-index-5        : 500 !default;
$z-index-6        : 600 !default;
$z-index-7        : 700 !default;
$z-index-8        : 800 !default;
$z-index-9        : 900 !default;
$z-index-10       : 1000 !default;

//  z-index 9
$modal__z-index   : $z-index-9 !default;

//  z-index 8
$overlay__z-index : $modal__z-index - 1 !default;
