/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.gallery-placeholder {
    .loading-mask {
        position: static;
        padding: 0 0 50%;
    }

    .loader img {
        position: absolute;
    }
}
