/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//
//  Variables
//  _____________________________________________

$account-nav-background              : $sidebar__background-color !default;
$account-nav-color                   : $color-white !default;

$account-nav-current-border          : 3px solid transparent !default;
$account-nav-current-border-color    : $color-brownie1 !default;
$account-nav-current-color           : $color-white !default;
$account-nav-current-font-weight     : $font-weight__semibold !default;

$account-nav-delimiter__border-color : $color-gray-light8 !default;

$account-nav-item-hover              : false !default;

$_password-default                   : $color-gray-light01 !default;
$_password-weak                      : #ffafae !default;
$_password-medium                    : #ffd6b3 !default;
$_password-strong                    : #c5eeac !default;
$_password-very-strong               : #81b562 !default;

.login-container {
    .block {
        &-new-customer {
            .actions-toolbar {
                margin-top: 25px;
            }
        }

        .block-title {
            @extend .abs-login-block-title;
            @include lib-font-size(18);
        }
    }

    .fieldset {
        @include lib-form-hasrequired(bottom);
        &:after {
            margin-top: 35px;
        }
    }
}

.block-addresses-list,
.block-addresses-default {
    font-size: 14px;
}

.block-addresses-list {
    .items.addresses {
        > .item {
            margin-bottom: $indent__base;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.form-address-edit {
    #region_id {
        display: none;
    }

    .actions-toolbar .action.primary {
        @extend .abs-button-l;
    }
}

.form-edit-account {
    .fieldset.password {
        display: none;
    }
}

.box-billing-address,
.box-shipping-address,
.box-information,
.box-newsletter {
    .box-content {
        line-height: 26px;
    }
}

//  Full name fieldset
.fieldset {
    .fullname {
        &.field {
            > .label {
                @extend .abs-visually-hidden;

                + .control {
                    width: 100%;
                }
            }
        }

        .field {
            @extend .abs-add-clearfix;
        }
    }
}

//
//  My account
//  ---------------------------------------------

.account {
    .column.main {
        h2 {
            margin-top: 0;
        }

        .toolbar {
            text-align: center;
            .limiter-options {
                width: auto;
            }
        }

        .limiter {
            >.label {
                @extend .abs-visually-hidden;
            }
        }

        .block:not(.widget) {
            @extend .abs-account-blocks;
        }
    }

    .sidebar-additional {
        margin-top: 40px;
    }

    .table-wrapper {
        &:last-child {
            margin-bottom: 0;
        }

        .action {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .table-return-items {
        .qty {
            .input-text {
                @extend .abs-input-qty;
            }
        }
    }
}

//  Checkout address (create shipping address)
.field.street {
    .field.additional {
        .label {
            @extend .abs-visually-hidden;
        }
    }
}

//
//  Account navigation
//  ---------------------------------------------

.account-nav {
    .title {
        @extend .abs-visually-hidden;
    }

    .content {
        @include lib-css(background, $account-nav-background);
        padding: 30px 20px 20px;
    }

    .item {
        margin: 3px 0 0;

        &:first-child {
            margin-top: 0;
        }

        a,
        > strong {
            @include lib-css(color, $account-nav-color);
            border-left: 3px solid transparent;
            display: block;
            padding: $indent__xs 18px $indent__xs 15px;
            font-size: 11px;
            text-transform: uppercase;
        }

        a {
            text-decoration: none;

            &:hover {
                @include lib-css(background, $account-nav-item-hover);
                color: $color-brownie1;
            }
        }

        &.current {
            a,
            strong {
                @include lib-css(border-color, $account-nav-current-border-color);
                @include lib-css(color, $account-nav-current-color);
                @include lib-css(font-weight, $account-nav-current-font-weight);
            }

            a {
                @include lib-css(border-color, $account-nav-current-border-color);
            }
        }

        .delimiter {
            border-top: 1px solid $account-nav-delimiter__border-color;
            display: block;
            margin: $indent__s 1.8rem;
        }
    }
}

//
//  Blocks & Widgets
//  ---------------------------------------------

.block {
    @extend .abs-margin-for-blocks-and-widgets;
    .column.main & {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .title {
        margin-bottom: $indent__s;

        strong {
            @include lib-heading(h4);
            .column.main & {
                font-size: $h3__font-size;
            }
        }
    }

    p:last-child {
        margin: 0;
    }

    .box-actions {
        margin-top: $indent__xs;
    }
}

//
//  Password Strength Meter
//  ---------------------------------------------

.field.password {
    .control {
        @include lib-vendor-prefix-display();
        @include lib-vendor-prefix-flex-direction();

        .mage-error {
            @include lib-vendor-prefix-order(2);
        }

        .input-text {
            @include lib-vendor-prefix-order(0);
            z-index: 2;
        }
    }
}

.password-strength-meter {
    background-color: $_password-default;
    height: $form-element-input__height;
    line-height: $form-element-input__height;
    padding: $form-element-input__padding;
    position: relative;
    z-index: 1;

    &:before {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        z-index: -1;
    }

    .password-none & {
        &:before {
            background-color: $_password-default;
            width: 100%;
        }
    }

    .password-weak & {
        &:before {
            background-color: $_password-weak;
            width: 25%;
        }
    }

    .password-medium & {
        &:before {
            background-color: $_password-medium;
            width: 50%;
        }
    }

    .password-strong & {
        &:before {
            background-color: $_password-strong;
            width: 75%;
        }
    }

    .password-very-strong & {
        &:before {
            background-color: $_password-very-strong;
            width: 100%;
        }
    }
}

.control.captcha-image {
    @include lib-css(margin-top, $indent__s);

    .captcha-img {
        vertical-align: middle;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .account {
        .column.main,
        .sidebar-additional {
            margin: 0;
        }
    }
}

@include max-screen($screen__m) {
    .login-container {
        .fieldset {
            &:after {
                text-align: center;
            }
        }
    }

    .account {
        .page.messages {
            margin-bottom: $indent__xl;
        }

        .toolbar {
            @extend .abs-pager-toolbar-mobile;
        }
    }

    .control.captcha-image {
        .captcha-img {
            @include lib-css(margin-bottom, $indent__s);
            display: block;
        }
    }

    .customer-account-index {
        .page-title-wrapper {
            position: relative;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .login-container {
        @extend .abs-add-clearfix-desktop;

        .block {
            @extend .abs-blocks-2columns;
            &.login {
                .actions-toolbar {
                    > .primary {
                        margin-bottom: 0;
                        margin-right: $indent__l;
                    }

                    > .secondary {
                        float: left;
                    }
                }
            }
        }

        .fieldset {
            &:after {
                @extend .abs-margin-for-forms-desktop;
            }

            > .field {
                > .control {
                    width: 55%;
                }
            }
        }
    }

    //  Full name fieldset
    .fieldset {
        .fullname {
            .field {
                .label {
                    @include lib-css(margin, $form-field-type-label-inline__margin);
                    @include lib-css(padding, $form-field-type-label-inline__padding);
                    @include lib-css(text-align, $form-field-type-label-inline__align);
                    @include lib-css(width, $form-field-type-label-inline__width);
                    box-sizing: border-box;
                    float: left;
                }

                .control {
                    @include lib-css(width, $form-field-type-control-inline__width);
                    float: left;
                }
            }
        }
    }

    .form.password.reset,
    .form.send.confirmation,
    .form.password.forget,
    .form.create.account {
        min-width: 600px;
        width: 50%;
    }

    //
    //  My account
    //  ---------------------------------------------

    .account.page-layout-2columns-left {
        .sidebar-main,
        .sidebar-additional {
            width: 23%;
        }

        .column.main {
            width: 75%;
        }
    }

    .account {
        .column.main {
            .block:not(.widget) {
                .block-content {
                    @extend .abs-add-clearfix-desktop;

                    .box {
                        @extend .abs-blocks-2columns;
                    }
                }
            }
        }

        .toolbar {
            @extend .abs-pager-toolbar;
        }
    }

    .block-addresses-list {
        .items.addresses {
            @extend .abs-add-clearfix-desktop;
            font-size: 0;

            > .item {
                display: inline-block;
                font-size: $font-size__base;
                margin-bottom: $indent__base;
                vertical-align: top;
                width: 48.8%;

                &:nth-last-child(1),
                &:nth-last-child(2) {
                    margin-bottom: 0;
                }

                &:nth-child(even) {
                    margin-left: 2.4%;
                }
            }
        }
    }

    //
    //  Welcome block
    //  ---------------------------------------------

    .dashboard-welcome-toggler {
        @extend .abs-visually-hidden-desktop;
    }

    .control.captcha-image {
        .captcha-img {
            margin: 0 $indent__s $indent__s 0;
        }
    }

    .account .page-main > .page-title-wrapper .page-title {
        padding-left: 25%;
    }
}

.form.password.reset,
.form.send.confirmation,
.form.password.forget,
.form.create.account {
    .fieldset > .field.choice:before,
    .fieldset > .field.no-label:before {
        display: none;
    }

    .fieldset > .legend {
        font-weight: bold;
        font-size: 18px;
        line-height: 26px;
    }
}


.actions-toolbar .secondary {
    float: left;
    clear: both;

    .action {
        @include lib-link-as-button();
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        background-color: unset;
        padding: 7px 10px;
        border-width: 1px;
        border-style: solid;
        color: $color-brownie1;
        border-color: rgba($color-brownie1, 0.5);
        border-radius: 0;

        &.back {
            &:before {
                content: "";
                display: inline-block;
                width: 25px;
                height: 20px;
                margin-right: 5px;
                background-repeat: no-repeat;
                vertical-align: -3px;
                background-image: url('../media/arrow-left.svg');
            }
        }
    }
}

.customer-account-logoutsuccess {
    .column.main > p {
        margin-left: 0;
    }
}

