/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.fotorama__grabbing {
    * {
        @extend .fotorama-grabbing-cursor;
    }
}
