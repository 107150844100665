/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//
//  Variables
//  _____________________________________________

$checkout-shipping-address__max-width           : 600px !default;
$checkout-shipping-address__margin-top          : 0 !default;

$checkout-shipping-item-icon__color             : $color-white !default;
$checkout-shipping-item-icon__content           : $icon-checkmark !default;

$checkout-shipping-item__border                 : 1px solid transparent !default;
$checkout-shipping-item__line-height            : 30px !default;
$checkout-shipping-item__margin                 : 0 0 $indent__base !default;
$checkout-shipping-item__padding                : $indent__base ($indent__l + 5px) $indent__base $indent__base !default;
$checkout-shipping-item__transition             : 0.3s border-color !default;
$checkout-shipping-item__width                  : 100% / 3 !default;
$checkout-shipping-item-tablet__width           : 100% / 2 !default;
$checkout-shipping-item-mobile__width           : 100% !default;
$checkout-shipping-item__active__border-color   : $color-brownie2 !default;
$checkout-shipping-item-icon__background-color  : $checkout-shipping-item__active__border-color !default;

$checkout-shipping-item-icon__selected__height  : 27px !default;
$checkout-shipping-item-icon__selected__width   : 29px !default;

$checkout-shipping-item-mobile__padding         : 0 0 15px !default;
$checkout-shipping-item-mobile__margin          : $checkout-shipping-item-mobile__padding !default;
$checkout-shipping-item-mobile__active__padding : 15px ($indent__l + 5px) 15px 18px !default;

$checkout-shipping-item-before__border-color    : $color-gray-light7 !default;
$checkout-shipping-item-before__height          : calc(100% - 20px) !default;

$checkout-shipping-method__border               : 1px solid $color-gray-light7 !default;
$checkout-shipping-method__padding              : $indent__base !default;

//
//  Common
//  _____________________________________________

.opc-wrapper {

    //
    //  Shipping Address
    //  ---------------------------------------------

    .form-login,
    .form-shipping-address {
        @include lib-css(margin-top, $checkout-shipping-address__margin-top);

        .fieldset {
            .note {
                font-size: 11px;
                text-transform: none;
                margin-top: $indent__s;
            }
        }

        .actions-toolbar {
            margin-bottom: 60px;
        }
    }

    .shipping-address-items {
        font-size: 0;
    }

    .shipping-address-item {
        @extend .abs-add-box-sizing;
        @include lib-css(border, $checkout-shipping-item__border);
        @include lib-css(line-height, $checkout-shipping-item__line-height);
        @include lib-css(margin, $checkout-shipping-item__margin);
        @include lib-css(padding, $checkout-shipping-item__padding);
        @include lib-css(transition, $checkout-shipping-item__transition);
        @include lib-css(width, $checkout-shipping-item-tablet__width);
        display: inline-block;
        font-size: $font-size__base;
        position: relative;
        vertical-align: top;
        word-wrap: break-word;
        line-height: 1.75;
        font-size: 14px;

        &.selected-item {
            @include lib-css(border-color, $checkout-shipping-item__active__border-color);

            &:after {
                @include lib-css(background, $checkout-shipping-item-icon__background-color);
                @include lib-css(color, $checkout-shipping-item-icon__color);
                @include lib-css(content, $checkout-shipping-item-icon__content);
                @include lib-css(font-family, $icons__font-name);
                @include lib-css(height, $checkout-shipping-item-icon__selected__height);
                @include lib-css(width, $checkout-shipping-item-icon__selected__width);
                font-size: 27px;
                line-height: 21px;
                padding-top: 2px;
                position: absolute;
                right: 0;
                text-align: center;
                top: 0;
            }

            .action-select-shipping-item {
                @extend .abs-no-display-s;
                visibility: hidden;
            }
        }
    }

    .field {
        &.addresses {
            @extend .abs-add-clearfix;
        }
    }

    .action-show-popup {
        margin: 0 0 $indent__base;

        > span {
            &:before {
                content: '+';
                padding-right: $indent__xs;
            }
        }
    }

    .action-select-shipping-item {
        float: right;
        margin: $indent__base 0 0;
    }

    .edit-address-link {
        @extend .abs-action-button-as-link;
        display: block;
        float: left;
        margin: 26px 5px 0 0;
    }
}

//
//  Shipping Methods
//  ---------------------------------------------

.checkout-shipping-method {
    .step-title {
        margin-bottom: 0;
    }

    .no-quotes-block {
        margin: $indent__base 0;
    }
}

.methods-shipping {
    .actions-toolbar {
        .action {
            &.primary {
                @extend .abs-button-l;
                margin: $indent__base 0 0;
            }
        }
    }
}

.table-checkout-shipping-method {
    thead {
        th {
            display: none;
        }
    }

    tbody {
        td {
            @include lib-css(border-top, $checkout-shipping-method__border);
            @include lib-css(padding-bottom, $checkout-shipping-method__padding);
            @include lib-css(padding-top, $checkout-shipping-method__padding);
            font-size: 14px;
            position: relative;

            &:first-child {
                padding-left: 0;
                padding-right: 0;
                width: 20px;
            }

            .shiptimize-choose-pickup {
                margin-left: 20px;
                text-align: right;
                display: block;
                float: right;
                width: auto;

                .shiptimize-pickup__description {
                    margin-top: 20px;
                    color: $color-gray-light8;
                    &:empty {
                        display: none;
                    }
                }
            }
        }

        tr {
            &:first-child {
                td {
                    border-top: none;
                }
            }
        }
        .row-error {
            td {
                border-top: none;
                padding-bottom: $indent__s;
                padding-top: 0;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .form-login {
            @include lib-css(border-bottom, 1px solid $checkout-shipping-item-before__border-color);
            @include lib-css(margin, $checkout-shipping-item-mobile__padding);
            @include lib-css(padding, $checkout-shipping-item-mobile__padding);
        }

        .shipping-address-item {
            @include lib-css(border-bottom, 1px solid $checkout-shipping-item-before__border-color);
            @include lib-css(margin, $checkout-shipping-item-mobile__margin);
            @include lib-css(padding, $checkout-shipping-item-mobile__padding);
            width: 100%;

            &.selected-item {
                @include lib-css(padding, $checkout-shipping-item-mobile__active__padding);
                border-bottom-width: 2px;

                .edit-address-link {
                    @include lib-css(right, $checkout-shipping-item-icon__selected__width + $indent__s);
                }
            }
        }

        .form-login,
        .form-shipping-address {
            @include lib-css(margin-top, $checkout-shipping-address__margin-top);
        }

        .action-select-shipping-item {
            float: none;
            margin-top: $indent__s;
            width: 100%;
        }

        .action-show-popup {
            width: 100%;
        }

        .edit-address-link {
            @include lib-icon-font(
                $icon-settings,
                $_icon-font-size         : 28px,
                $_icon-font-line-height  : 28px,
                $_icon-font-text-hide    : true,
                $_icon-font-color        : $color-gray19,
                $_icon-font-color-hover  : $color-gray19,
                $_icon-font-color-active : $color-gray19
            );
            margin: 0;
            position: absolute;
            right: 0;
            top: 1px;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .checkout-shipping-method {
        .actions-toolbar {
            > .primary {
                float: right;
            }

            .action {
                &.primary {
                    margin: 0;
                }
            }
        }
    }

    .opc-wrapper {
        .form-login,
        .form-shipping-address {
            @include lib-css(max-width, $checkout-shipping-address__max-width);
        }
    }
    .table-checkout-shipping-method {
        width: 100%;
    }
}

@include min-screen($screen__l) {
    .opc-wrapper {
        .shipping-address-item {
            @include lib-css(width, $checkout-shipping-item__width);

            &:before {
                @include lib-css(background, $checkout-shipping-item-before__border-color);
                @include lib-css(height, $checkout-shipping-item-before__height);
                content: '';
                left: 0;
                position: absolute;
                top: 0;
                width: 1px;
            }

            &:nth-child(3n + 1) {
                &:before {
                    display: none;
                }
            }

            &.selected-item {
                &:before {
                    display: none;
                }

                + .shipping-address-item {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
    .table-checkout-shipping-method {
        min-width: 500px;
    }
}


// shiptimize

.shiptimize-pickup {
    .shiptimize-pickup__close {
        @include lib-button-reset();
        @include lib-button-icon(
                $icon-remove,
            $_icon-font-color       : $minicart-icons-color,
            $_icon-font-size        : $modal-action-close__font-size,
            $_icon-font-line-height : $modal-action-close__font-size,
            $_icon-font-text-hide   : true
        );
        position: absolute;
        right: 15px;
        left: auto;
        top: 10px;

        &:hover {
            &:before {
                color: $modal-action-close__hover__color;
            }
        }

        &:after {
            display: none;
        }
    }

    .shiptimize-pickup__options {
        background-color: $color-black;
        color: $color-white;
        box-sizing: border-box;
    }

    .shiptimize-pickup__point_description {
        font-size: 12px;
        display: block;
        margin: 0 0 0 40px;
    }

    .shiptimize-pickup__point {
        margin-bottom: 20px;

        input[type="radio"] {
            float: left;
            margin: 6px;
        }
    }

    .shiptimize-pickup__validate {
        width: 100%;
        background-color: #fff;
    }

    .shiptimize-pickup__overlay {
        background-color: rgba(0, 0, 0, 0.75);
    }

    .shiptimize-pickup__error {
        padding: 60px;
        @include lib-css(box-shadow, $modal__box-shadow);
        font-family: "Inter", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 1.625;
        font-size: 1.6rem;
    }
}

