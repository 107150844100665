/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

.candle-in-slider-widget {
    margin-bottom: 50px;

    .slider-container {
        padding-bottom: 0;
        margin-bottom: 50px;
        height: 350px;

        @include min-screen($screen__s) {
            height: 450px;
        }

        @include min-screen($screen__m) {
            height: 550px;
        }

        @include min-screen($screen__l) {
            height: 950px;
        }

        .candle-in-slider {
            position: absolute;
            right: 0;
            left: 100px;
            height: 300px;
            padding-bottom: 0;

            @include min-screen($screen__s) {
                height: 400px;
            }

            @include min-screen($screen__m) {
                height: 500px;
            }

            @include min-screen($screen__l) {
                height: 900px;
            }

            @include max-screen($screen__l) {
                left: 0;
            }

            &:before {
                content: "";
                display: block;
                position: absolute;
                background-color: $color-brownie1;
                top: 50%;
                right: 0;
                left: -100px;
                bottom: -70px;
            }

            .slide .slide-content {
                @include full-spread();
                display: none;
            }

            .slider-nav {
                left: 0;
                bottom: -40px;

                @include max-screen($screen__l) {
                    left: 15px;
                }
            }
        }
    }


    .text-container {
        position: relative;
        padding-bottom: 90px;

        .titles-container {
            position: relative;
            z-index: 1;

            .subtitle {
                font-size: 18px;
                line-height: 26px;
                color: rgba($color-white, 0.5);
            }

            .title {
                font-family: $font-family-name__headings;
                font-size: 34px;
                color: $color-white;
                position: relative;
                padding: 0;
                line-height: 1.2;
            }

            @include min-screen($screen__l) {
                max-width: 50%;
            }
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            background-color: $color-brownie1;
            top: -50px;
            right: -50%;
            left: -50%;
            bottom: 0;

            @include max-screen($screen__m) {
                right: -15px;
                left: -15px;
            }
        }

        .text-content {
            position: relative;
            z-index: 1;
            font-size: 16px;
            line-height: 26px;
            color: $color-white;

            @include min-screen($screen__m) {
                max-width: 50%;
            }
        }
    }
}
