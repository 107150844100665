/*!
 * @author       Tony Coelho
 * @copyright    © Candle In 2021
 */

//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background           : $color-yellow-light2 !default;
$message-global-note__border-color         : $color-yellow-light3 !default;
$message-global-note__color                : $text__color !default;

$message-global-note-link__color           : $link__color !default;
$message-global-note-link__color-hover     : $link__hover__color !default;
$message-global-note-link__color-active    : $link__active__color !default;

$message-global-caution__background        : $color-red9 !default;
$message-global-caution__border-color      : none !default;
$message-global-caution__color             : $color-white !default;

$message-global-caution-link__color        : $link__color !default;
$message-global-caution-link__color-hover  : $link__hover__color !default;
$message-global-caution-link__color-active : $link__active__color !default;

//  Header
$header__background-color                  : false !default;
$header-icons-color                        : $color-gray56 !default;
$header-icons-color-hover                  : $color-gray20 !default;

$addto-color                               : $text__color__muted !default;
$addto-hover-color                         : $primary__color !default;

$minicart-icons-color                      : $color-brownie1 !default;
$minicart-icons-color-hover                : $color-brownie-light1 !default;

$button__shadow                            : none !default;

$h1__margin-bottom__desktop                : $indent__xl !default;

//  Footer
$footer__background-color                  : false !default;


body {
    @include lib-css(background-color, $page__background-color);
}

//
//  Header
//  ---------------------------------------------

.header {
    &.content {
        @extend .abs-add-clearfix;
        padding-top: $indent__s;
        position: relative;
    }

    .link a {
        width: 22px;
        height: 22px;
        font-size: 0;
        background-repeat: no-repeat;
        background-position: center;
        display: inline-block;
        vertical-align: top;
    }

    .link.my-account-link a {
        svg.dummy {
            fill: $color-black;
        }

        &:hover svg.dummy {
            fill: rgba($color-black, 0.5);
        }
    }

    .link.wishlist a {
        vertical-align: -2px;

        svg.heart {
            fill: $color-black;
        }

        &:hover svg.heart {
            fill: rgba($color-black, 0.5);
        }
    }
}

.logo {
    max-width: 50%;
    position: relative;
    z-index: 100;
    display: block;
    width: 80px;
    height: 80px;
    margin: 0 auto;

    img {
        max-height: inherit;
    }

    svg {
        display: block;
        margin: 0 auto;

        .logo-bg {
            fill: $color-brownie1;
        }

        .flames {
            fill: $color-white;
        }

        .logo-text {
            display: none;
            @include min-screen($screen__m) {
                display: block;
            }
        }
    }

    .page-print & {
        float: none;
    }

    @include max-screen($screen__m) {
        width: 45px;
        height: 45px;
        margin-top: 10px;
        margin-bottom: 10px;
        overflow: hidden;

        svg {
            width: 100%;
            margin-top: -24px;
        }
    }
}

.page-main {
    margin-bottom: 50px;

    > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }

        .page-title {
            display: block;
            margin: 30px 0;
            font-family: $font-family-name__headings;
            font-weight: normal;
            font-size: 26px;
            line-height: 32px;

            @include min-screen($screen__m) {
                margin: 60px 0;
                font-size: 56px;
                line-height: 62px;
                max-width: 550px;
            }

            @include max-screen($screen__m) {
                border-bottom: 1px solid $color-gray-light7;
                padding-bottom: 30px;
            }
        }
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        @include lib-css(background, $color-gray94);
        @include lib-css(padding, $indent__s);
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }

    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;

        .actions {
            margin-top: $indent__s;
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
    @include lib-css(background-color, $footer__background-color);
    margin-top: auto;
}

.footer {
    &.content {
        border-top: 1px solid $border-color__base;
        margin-top: 25px;
        padding-bottom: 25px;
        padding-top: 25px;

        ul {
            @extend .abs-reset-list;
        }

        .links {
            > li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
        }
    }

    .copyright,
    .bugs {
        display: block;
        margin: 20px 0 0;
    }
}

.page-header,
.page-footer {
    .switcher {
        margin-right: 10px;

        .options {
            ul.dropdown {
                a {
                    display: block;
                    padding: 8px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        li {
            font-size: $font-size__s;
            margin: 0;
        }

        .label {
            @extend .abs-visually-hidden;
        }

        strong {
            font-weight: $font-weight__regular;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        @include lib-css(margin, $indent__base 0);
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

.page-header {
    border: 0;
    position: relative;
    z-index: 20;

    @include min-screen($screen__m) {
        margin-bottom: 90px;
    }

    @include max-screen($screen__m) {
        margin-bottom: 30px;
        border-bottom: 1px solid $color-brownie1;
    }

    .header.panel {
        @extend .abs-add-clearfix-desktop;
        padding-bottom: 0;
    }

    .switcher {
        display: inline-block;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    .page-wrapper {
        overflow: hidden;
        position: relative;
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer

        > .breadcrumbs,
        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }

        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }

    .navigation ul {
        padding: 0 8px;
    }

    .header {
        &.panel {
            > .header.links {
                position: absolute;
                top: 50%;
                right: 60px;
                font-size: 0;
                @include lib-list-inline();
                margin-left: auto;
                margin-top: -9px;

                > li {
                    font-size: $font-size__base;
                    margin: 0 30px 0 0;

                    &.welcome,
                    a {
                        line-height: 1.4;
                    }

                    &.welcome {
                        a {
                            @include lib-css(padding-left, $indent__xs);
                        }
                    }
                }
            }
        }

        &.content {
            @extend .abs-add-clearfix-desktop;
            padding: $indent__l $indent__base 0;
        }
    }

    .nav-open .page-header {
        z-index: 40;
    }

    .page-main {
        > .page-title-wrapper {
            .page-title {
            }

            .page-title + .action {
                margin-top: $indent__base;
            }
        }
    }

    .footer {
        &.content {
            .block {
                float: right;
            }

            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }
    }
}
